import React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import { maskTel } from "../../../hooks/common";
import "./style.css";

const StudentForm = (prop) => {
  let { submit, setValues, values } = prop.data;

  const { t } = useTranslation();

  // maskTel(document.querySelectorAll(`input[type=tel]`));
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = useForm({
    mode: "onBlur",
    // defaultValues: { studentPhone: "+998 XX XXX XX XX" },
  });

  const options = [
    // {
    //   value: t("home.registration.student.select.grades1and2"),
    //   label: t("home.registration.student.select.grades1and2"),
    // },
    // {
    //   value: t("home.registration.student.select.grades3and4"),
    //   label: t("home.registration.student.select.grades3and4"),
    // },
    // {
    //   value: t("home.registration.student.select.grades5"),
    //   label: t("home.registration.student.select.grades5"),
    // },
    // {
    //   value: t("home.registration.student.select.grades6and7"),
    //   label: t("home.registration.student.select.grades6and7"),
    // },
    // {
    //   value: t("home.registration.student.select.grades8"),
    //   label: t("home.registration.student.select.grades8"),
    // },
    {
      value: t("home.registration.student.select.grades9"),
      label: t("home.registration.student.select.grades9"),
    },
    {
      value: t("home.registration.student.select.grades10"),
      label: t("home.registration.student.select.grades10"),
    },
    {
      value: t("home.registration.student.select.grades11"),
      label: t("home.registration.student.select.grades11"),
    },
  ];
  return (
    <>
      <div className="form-candidate">
        <form onSubmit={handleSubmit(submit)}>
          <div className="ip out s1">
            <label htmlFor="full-name">
              {t("home.registration.student.fullName")}
            </label>
            <input
              {...register("fullName", {
                required: t("home.registration.student.fullNameError"),
              })}
              name="fullName"
              id="full-name"
              type="text"
              placeholder={t("home.registration.student.fullName")}
              value={values?.fullName || ""}
              onChange={(e) =>
                setValues({ ...values, fullName: e.target.value })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.fullName && (
                <span>{errors?.fullName.message || ""}</span>
              )}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="date">{t("home.registration.student.date")}</label>
            <input
              {...register("date", {
                required: t("home.registration.student.dateError"),
              })}
              name="date"
              id="date"
              type="date"
              placeholder={t("home.registration.student.date")}
              value={values?.dateOfBirth || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  dateOfBirth: e.target.value,
                })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.date && <span>{errors?.date.message}</span>}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="studentEmail">
              {t("home.registration.student.email")}
            </label>
            <input
              {...register("studentEmail", {
                required: t("home.registration.student.emailError"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Emailni to'g'ri kiriting",
                },
              })}
              name="studentEmail"
              id="email"
              type="email"
              placeholder={t("home.registration.student.email")}
              value={values?.email || ""}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
            <div style={{ color: "red" }}>
              {errors?.studentEmail && (
                <span>{errors?.studentEmail.message}</span>
              )}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="phone">
              {t("home.registration.student.phone")}
            </label>
            <input
              {...register("studentPhone", {
                required: t("home.registration.student.phoneError"),
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\. ]?[0-9]{2}[-\s\. ]?[0-9]{3}[-\s\. ]?[0-9]{2}[-\s\. ]?[0-9]{2}$/,
                  message: t("home.registration.student.phoneMessageError"),
                },
              })}
              maxLength={17}
              name="studentPhone"
              id="phone"
              type="tel"
              placeholder="+998 12 345 67 89"
              value={values?.phone || ""}
              onChange={(e) => setValues({ ...values, phone: e.target.value })}
            />
            <div style={{ color: "red" }}>
              {errors?.studentPhone && (
                <span>{errors?.studentPhone.message}</span>
              )}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="school-number">
              {" "}
              {t("home.registration.student.schoolNumber")}
            </label>
            <input
              {...register("studentSchoolNumber", {
                required: t("home.registration.student.schoolNumberError"),
              })}
              name="studentSchoolNumber"
              id="school-number"
              type="number"
              placeholder={t("home.registration.student.schoolNumber")}
              value={values?.schoolNo || ""}
              onChange={(e) =>
                setValues({ ...values, schoolNo: e.target.value })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.studentSchoolNumber && (
                <span>{errors?.studentSchoolNumber.message}</span>
              )}
            </div>
          </div>
          <div className="ip out s1">
            <Controller
              control={control}
              name="grade"
              rules={{ required: t("home.registration.student.selectError") }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <>
                  {" "}
                  <Select
                    name="grade"
                    options={options}
                    onChange={(e) => onChange(e)}
                  />
                  <div style={{ color: "red" }}>
                    {error?.message && <span>{error?.message}</span>}
                  </div>
                </>
              )}
            ></Controller>
          </div>
          <button
            className={`${isSubmitting && "student-form_disabled"}`}
            disabled={isSubmitting}
          >
            {t("home.registration.button")}
          </button>
        </form>
      </div>
    </>
  );
};

export default StudentForm;
