const ru = {
  navbar: {
    home: "Главная",
    aboutUs: "О нас",
    faqs: "Вопросы и ответы",
    contactUs: "Контакты",
    profil: "Профиль",
    login: "Войти",
    logout: "Выйти",
    register: "Зарегистрироваться",
    profile: "Профиль",
    settings: "Настройки",
    olimpiad: "Олимпиада",
  },
  footer: {
    followUs: "Следуйте за нами:",
    linksTitle: "Быстрые ссылки",
    aboutUs: "О нас",
    faqs: "Вопросы и ответы",
    contactUs: "Контакты",
    phoneInfo: "Нужна помощь?",
    addOlimpiad:
      "Присоединяйтесь к олимпиаде по английскому языку уже сегодня!",
    address: "Проспект Бунедкор, 2-3, здание КИУ, Ташкент, Узбекистан, 100043",
  },
  home: {
    header: {
      title: `Получите шанс принять участие в международной олимпиаде вместе с Центром MU champs.`,
      miniTitle:
        "Добро пожаловать на международную олимпиаду центра MU champs!",
      subtitle:
        "Эта многоэтапная олимпиада предлагает уникальную возможность проверить свои способности, обучиться и выиграть незабываемые призы и, в частности, специальную поездку в Великобританию! Пройдя ряд тщательно продуманных этапов, участники смогут продемонстрировать свои знания английского языка, принять участие в стимулирующих лингвистических заданиях и побороться за честь стать лучшими знатоками английского языка в своих классах. Присоединяйтесь к нам в этом увлекательном путешествии, чтобы повысить уровень владения английским языком, испытать свои возможности и выйти победителем!",
      studentImg: "Студенты",
      testImg: "Тесты",
    },
    olimpiad: {
      title: "MU champs Олимпиада",
      subtitle:
        "Получите шанс принять участие в международной олимпиаде вместе с Центром MU champs.",
    },
    roadmap: {
      title: "Карта",
      happyPartners: "Партнеры счастливы",
      stage0: {
        title: "Этап 0: Регистрация",
        subtitle1:
          "Учителя: Зарегистрируйте свою школу и укажите контактные данные.",
        subtitle2: "Ученики: Выберите или укажите свою школу для регистрации.",
      },
      stage1: {
        title: "Этап 1: Онлайн-тест",
        subtitle:
          "На этом этапе учащиеся пройдут онлайн-тест Cambridge English Placement, и после онлайн-тестирования только первые 100 участников из каждого класса перейдут на этап 2.",
      },
      stage2: {
        title: "Этап 2: Linguaskill Listening and Reading",
        subtitle:
          "Отобранная группа участников продолжит демонстрировать свои навыки аудирования и чтения с помощью экзамена Cambridge Assessment English Linguaskill. Участники будут разделены на группы в соответствии с их оценками (9-10-11), а их результаты будут рассчитываться отдельно, чтобы обеспечить справедливость.",
      },
      stage3: {
        title: "Этап 3: Writing Speaking Linguaskill",
        subtitle:
          "На этом этапе студенты соревнуются в выполнении письменных и устных заданий Cambridge Assessment English Linguaskill. Баллы будут подсчитываться в соответствии с оценками (9-10-11), в результате чего будут определены по три выдающихся победителя из каждого класса, продемонстрировавшие выдающиеся способности.",
      },
      finalWinners: {
        title: "Финальные победители:",
        subtitle1:
          "Кульминацией конкурса Elevate English Challenge станет чествование 9 победителей из разных классов, каждый из которых будет награжден за выдающиеся достижения в владении английским языком:",
        subtitle2:
          "Присоединяйтесь к нам в этом беспрецедентном приключении и воспользуйтесь возможностью получить признание на международном уровне. Зарегистрируйтесь сейчас, чтобы стать гражданином мира!",
        grades9: "• 9 классы: 3 лучших места",
        grades10: "• 10 классы: 3 лучших места",
        grades11: "• 11 классы: 3 лучших места",
      },
    },
    whyChooseEdu: {
      title: "Почему стоит выбрать MU champs:",
      box1: {
        title: "Экспертиза",
        subtitle:
          "Стать гражданином мира, получив международно признанные сертификаты.",
      },
      box2: {
        title: "Качество",
        subtitle:
          "Мы придерживаемся самых высоких стандартов качества и честности во всех аспектах нашей деятельности, гарантируя, что наши клиенты получат справедливые и точные оценки.",
      },
      box3: {
        title: "Доступность",
        subtitle:
          "Мы стремимся сделать наши экзаменационные услуги доступными для всех: удобное местоположение, гибкие варианты расписания и доброжелательное обслуживание клиентов.",
      },
    },
    registration: {
      title: "Регистрация",
      subtitle: "Олимпиада стала проще простого",
      student: {
        title: "Для студентов",
        fullName: "Полное имя",
        fullNameError: "Требуется полное имя",
        date: "Дата рождения",
        dateError: "Требуется указать дату рождения",
        email: "Адрес электронной почты",
        emailError: "Требуется указать адрес электронной почты",
        phone: "Номер телефона",
        phoneError: "Требуется указать номер телефона",
        phoneMessageError: "Введите правильный номер телефона",
        schoolNumber: "Номер школы",
        schoolNumberError: "Требуется указать номер школы",
        selectError:
          "В обязательном порядке необходимо выбрать один из следующих вариантов",
        select: {
          grades1and2: "Классы 1-2",
          grades3and4: "Классы 3-4",
          grades5: "Класс 5",
          grades6and7: "Классы 6-7",
          grades8: "Класс 8",
          grades9: "Класс 9",
          grades10: "Класс 10",
          grades11: "Класс 11",
        },
      },
      school: {
        title: "Для школ",
        schoolNumber: "Номер школы",
        schoolNumberError: "Требуется указать номер школы",
        constactPerson: "Полное имя контактного лица",
        constactPersonError: "Необходимо указать полное имя контактного лица",
        address: "Адрес",
        addressError: "Требуется указать адрес",
        email: "Электронная почта",
        emailError: "Требуется электронная почта",
        phone: "Номер телефона",
        phoneError: "Требуется указать номер телефона",
        phoneMessageError: "Введите правильный номер телефона",
      },
      button: "Представить",
    },
  },
  about: {
    header: {
      titleMain: "О нас",
      subtitleMain: `О центре MU champs Exams 
         Добро пожаловать в Центр экзаменов MU champs, где вы можете получить услуги по сдаче экзаменов, признанных на международном уровне, в Узбекистане. В MU champs мы стремимся предоставлять высококачественные экзаменационные решения, которые позволяют людям достигать своих академических и профессиональных целей`,
      title: "Наша миссия",
      subtitle:
        "Наша миссия в MU champs Exams Centre заключается в расширении возможностей отдельных лиц и учреждений благодаря доступу к экзаменационным услугам мирового класса. Мы верим, что образование - это ключ к раскрытию возможностей и реализации потенциала, и мы стремимся поддерживать наших клиентов на их образовательном пути.",
      students: "Студенты",
      partners: "Партнеры",
    },
    aboutUs: {
      title: "О нас",
      box1: {
        title: "О центре экзаменов MU champs",
        subtitle:
          "Добро пожаловать в Экзаменационный центр MU champs, который является вашим главным центром предоставления международно признанных экзаменационных услуг в Узбекистане. В MU champs мы стремимся предоставлять высококачественные экзаменационные решения, которые позволяют людям достигать своих академических и профессиональных целей.",
      },
      box2: {
        title: "Кто мы:",
        subtitle:
          "Центр экзаменов MU champs - надежный лидер в области экзаменационных и курсовых услуг, обслуживающий студентов, специалистов, преподавателей и учебные заведения по всему Узбекистану. Благодаря команде преданных своему делу профессионалов и стремлению к совершенству, мы стремимся предоставить исключительный сервис и поддержку каждому клиенту.",
      },
      box3: {
        title: "Что мы предлагаем:",
        subtitle:
          "MU champs предлагает широкий спектр международно признанных экзаменов и квалификаций для удовлетворения разнообразных потребностей наших клиентов. Если вы хотите продемонстрировать уровень владения английским языком, продолжить обучение за рубежом или получить профессиональную сертификацию, мы предлагаем такие экзамены, как IELTS, LINGUASKILL, TKT, DELTA и курсы CELTA. А также ресурсы, которые помогут вам добиться успеха.",
      },
    },
    category: {
      IELTS: {
        title: "IELTS",
        subtitle:
          "Мы являемся первым центром тестирования IELTS в Узбекистане, аккредитованным IDP Australia. Удобное расположение MU champs в центре Ташкента рядом с метро Новза позволяет нам обеспечить комфортные условия для сдачи экзамена. Главный принцип нашего центра - клиентоориентированный подход. Кандидаты могут зарегистрироваться как онлайн, так и в нашем офисе, где наши приветливые сотрудники всегда готовы оказать вам всестороннюю поддержку. Международная система тестирования по английскому языку (IELTS) - один из наиболее широко признанных в мире тестов на знание английского языка. Он предназначен для оценки языковых способностей кандидатов, которым необходимо учиться или работать там, где английский является языком общения. IELTS принимается более чем 10 000 организаций по всему миру, включая университеты, работодателей, иммиграционные службы и профессиональные организации.",
      },
      LINGUASKILL: {
        title: "LINGUASKILL",
        subtitle:
          "Linguaskill - это тип теста на знание английского языка, разработанный компанией Cambridge Assessment English, входящей в состав Кембриджского университета. Он предназначен для оценки уровня владения английским языком в академических, профессиональных или личных целях. Тест оценивает все четыре языковых навыка: говорение, письмо, чтение и аудирование. Linguaskill основан на компьютерных технологиях и является адаптивным, то есть уровень сложности вопросов меняется в зависимости от ответов испытуемого. Адаптивный характер теста позволяет точно определить уровень владения языком.",
      },
      TKT: {
        title: "TKT",
        subtitle:
          "Teaching Knowledge Test (TKT) - это еще один экзамен, предлагаемый Cambridge Assessment English, специально разработанный для преподавателей английского языка как иностранного. Его цель - оценить основные знания и навыки, необходимые для эффективного преподавания английского языка. TKT не оценивает способность преподавать, а скорее фокусируется на понимании языковых концепций, методик преподавания и методов управления классом. TKT доступен во всем мире, и его могут пройти как опытные, так и начинающие преподаватели английского языка. Он служит ценным инструментом профессионального развития, позволяя учителям продемонстрировать свои знания и понимание принципов и практики преподавания английского языка. Сдача TKT может повысить квалификацию учителя и открыть возможности для карьерного роста в сфере преподавания английского языка.",
      },
      DELTA: {
        title: "DELTA",
        subtitle:
          "Диплом Delta (диплом преподавателя английского языка для носителей других языков) - это продвинутая квалификация для опытных преподавателей английского языка, предлагаемая Cambridge Assessment English. Она предназначена для преподавателей, которые уже имеют определенный опыт в преподавании английского языка как иностранного или второго языка и хотят развить свои знания и навыки.",
      },
      CELTA: {
        title: "CELTA",
        subtitle:
          "CELTA расшифровывается как Certificate in Teaching English to Speakers of Other Languages. Это высоко ценимая начальная квалификация для тех, кто хочет начать карьеру преподавателя английского языка как иностранного (TEFL). Курс CELTA разработан и проводится компанией Cambridge Assessment English, входящей в состав Кембриджского университета. Курсы CELTA являются интенсивными и обычно длятся четыре недели. В нашем центре также доступны варианты очного и заочного обучения, в том числе онлайн. В ходе курса слушатели изучают различные аспекты преподавания английского языка, включая планирование уроков, управление классом, анализ языка и практику преподавания.",
      },
    },
    statisticas: {
      title: "Цифры не лгут",
      subtitle: "Более 100 новых соискателей каждую неделю",
      studentReg: "Зарегистрированные студенты",
      applicants: "Новые кандидаты",
      tests: "Тесты",
      awards: "Награды",
    },
    inTouch: {
      title: "Свяжитесь с нами:",
      subtitle:
        "Готовы сделать следующий шаг на своем образовательном пути? Свяжитесь с центром MU champs Exams сегодня, чтобы узнать больше о наших услугах и запланировать экзамен. Мы с нетерпением ждем возможности помочь вам в достижении ваших целей!",
      button: "Cвязаться",
    },
  },
  faq: {
    title: "Часто задаваемые вопросы:",
    question1: {
      question:
        "Что такое Международная олимпиада по английскому языку для школьников?",
      answer:
        "Международная олимпиада по английскому языку, проводимая Центром экзаменов MU champs, - это соревнование, которое помогает школьникам улучшить и продемонстрировать свои знания английского языка. Она награждает тех, кто действительно хорошо владеет английским языком.",
    },
    question2: {
      question:
        "Какие возможности открывает участие в Международной олимпиаде по английскому языку для студентов?",
      answer:
        "Участие в Международной олимпиаде по английскому языку дает студентам шанс проверить свои знания английского языка, узнать больше и, возможно, выиграть потрясающие призы, включая специальную поездку в Великобританию!",
    },
    question3: {
      question:
        "Какие уровни сложности представлены в Международной олимпиаде по английскому языку?",
      answer:
        "В Международной олимпиаде по английскому языку все участники сначала сдают официальный тест Cambridge English Assessment. Затем определенное количество участников переходит в следующий тур и соревнуется с помощью теста Linguaskill, который также разработан Cambridge Assessment English.",
    },
    question4: {
      question:
        "Как проходит участие в Международной олимпиаде по английскому языку?",
      answer: "📌 Registration process ni yozib chiqasiz. Proggress....",
    },
    question5: {
      question:
        "Какие требования предъявляются к участникам Международной олимпиады по английскому языку?",
      answer:
        "Участники Международной олимпиады по английскому языку должны быть учениками 9, 10 или 11 классов и иметь при себе удостоверение личности или паспорт для выезда за границу. Это главное требование для тех, кто хочет зарегистрироваться и принять участие в олимпиаде.",
    },
    question6: {
      question:
        "Какие подготовительные ресурсы доступны для участников Международной олимпиады по английскому языку?",
      answer:
        "Участники Международной олимпиады по английскому языку могут подготовиться к 1 туру, используя Cambridge English Placement (https://www.cambridgeenglish.org/test-your-english/). Для последующих туров они могут использовать практические материалы Linguaskill (https://www.cambridgeenglish.org/exams-and-tests/linguaskill/information-about-the-test/practice-materials/).",
    },
  },
  contactUs: {
    infos: {
      call: "Позвоните нам",
      email: "Электронная почта",
      address: "Адрес",
      location:
        "Проспект Бунедкор, 2-3, здание КИУ, Ташкент, Узбекистан, 100043",
    },
    inputs: {
      name: "Ваше имя",
      email: "Ваш адрес электронной почты",
      subject: "Предмет",
      question: "Ваши вопросы...",
    },
    button: "Отправить Сообщение",
  },
  signIn: {
    tabSchool: "Для Школ",
    tabStudent: "Для Студентов",
    title: "Aвторизоваться",
    userNameTitle: "Aдрес электронной почты",
    userNameTitleError: "Требуется aдрес электронной почты",
    userName: "Aдрес электронной почты",
    password: "Пароль",
    passwordError: "Необходим пароль",
    fogotPassword: "Забыли пароль?",
    button: "Авторизоваться",
    signUpTitle: "Еще не зарегистрировались?",
    signUp: "Подписывать",
  },
  signUp: {
    title: "Создать аккаунт",
    student: {
      title: "Студент",
      userNameTitle: "Имя пользователя или адрес электронной почты",
      userName: "Имя пользователя",
      password: "Пароль",
      confirmPassword: "Подтвердите пароль",
    },
    school: {
      title: "Школа",
      userNameTitle: "Имя пользователя или адрес электронной почты",
      userName: "Имя пользователя",
      password: "Пароль",
      confirmPassword: "Подтвердите пароль",
    },
    agree: {
      title: "Я согласен с этимe",
      link: "Условия использования",
    },
    button: "Зарегистрировать",
    signInTitle: "У вас уже есть учетная запись?",
    signIn: "Войдите в систему Здесь",
  },
  changePassword: {
    tabSchool: "Школы",
    tabStudent: "Студент",
    title: "Изменить пароль",
    userNameTitle: "Aдрес электронной почты",
    userNameTitleError: "Требуется aдрес электронной почты",
    userName: "Aдрес электронной почты",
    currentPassword: "Текущий пароль",
    newPassword: "Новый пароль",
    passwordError: "Необходим пароль",
    button: "Изменить пароль",
  },
};

export default ru;
