import React, { useState } from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import { setCookies } from "../../hooks/cookie";
import { toast } from "react-toastify";
import useApi from "../../hooks/use-api";
import { changePassword, changePasswordSchool } from "../../api/client";
import { useTranslation } from "react-i18next";

import "./style.css";
import StudentChangePassword from "./studentChangePassword";
import SchoolChangePassword from "./schoolChangePassword";

const PasswordChange = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [dataSchool, setDataSchool] = useState({});

  const changePasswordApi = useApi(changePassword, "data");
  const changePasswordSchoolApi = useApi(changePasswordSchool, "data");

  const handleSubmit = async (data) => {
    let result = await changePasswordApi.request(data);
    if (result.status === 200) {
      toast.success(`Logged in successfully.`);
      setCookies("token", result?.data?.token);
      setData({});
      navigate("/");
    } else {
      console.log(result);
      toast.error(`Error occured: ${result.data.message}`);
    }
  };
  const handleSchoolSubmit = async (data) => {
    let result = await changePasswordSchoolApi.request(data);

    if (result.status === 200) {
      toast.success(`Logged in successfully.`);
      setCookies("token", result?.data?.token);
      setData({});
      navigate("/");
    } else {
      console.log(result);
      toast.error(`Error occured: ${result.data.message}`);
    }
  };
  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs className="wd-form-login tf-tab">
            <TabList className="menu-tab">
              <Tab className="ct-tab">{t("changePassword.tabStudent")}</Tab>
              <Tab className="ct-tab">{t("changePassword.tabSchool")}</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <StudentChangePassword
                  data={{
                    submit: handleSubmit,
                    setData,
                    data,
                  }}
                />
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <SchoolChangePassword
                  data={{
                    submit: handleSchoolSubmit,
                    setDataSchool,
                    dataSchool,
                  }}
                />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default PasswordChange;
