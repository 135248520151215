import React, { useState } from "react";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

import img1 from "../../assets/images/about1.jpg";
import shape1 from "../../assets/images/review/shape.png";
import icon1 from "../../assets/images/review/icon1.png";
import icon2 from "../../assets/images/review/icon2.png";
import icon3 from "../../assets/images/review/icon3.png";
// import client1 from "../../assets/images/review/client.jpg";

function Review() {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const { t } = useTranslation();

  const onVWEnter = () => {
    setViewPortEntered(true);
  };

  return (
    <section className="inner-review-section">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="wd-review-job thumb2 widget-counter stc">
              <div className="thumb mx-1">
                <img
                  style={{
                    display: "inline-block",
                    maxHeight: "600px",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                  src={img1}
                  alt="images"
                />
              </div>
              <div className="trader-box">
                <div className="content">
                  <h3 className="number wrap-counter">
                    <Waypoint onEnter={onVWEnter}>
                      <span>
                        {viewPortEntered && (
                          <CountUp
                            className="number"
                            end={20}
                            suffix="K+"
                            duration={3}
                          />
                        )}
                      </span>
                    </Waypoint>
                  </h3>
                  <div className="des">{t("about.header.students")}</div>
                </div>
                <div className="shape ani7">
                  <img src={shape1} alt="images" />
                </div>
              </div>
              <div className="tes-box ani5">
                <div className="client-box">
                  {/* <div className="avt">
                    <img src={client1} alt="images" />
                    <div className="badge"> </div>
                  </div> */}
                  <div className="content">
                    <h6 className="number wrap-counter">
                      <Waypoint onEnter={onVWEnter}>
                        <span>
                          {viewPortEntered && (
                            <CountUp
                              className="number"
                              end={200}
                              suffix="+"
                              duration={3}
                            />
                          )}
                        </span>
                      </Waypoint>
                    </h6>
                    <div className="des">{t("about.header.partners")}</div>
                  </div>
                </div>
              </div>
              <div className="icon1 ani3">
                <img src={icon1} alt="images" />
              </div>
              <div className="icon2 ani4">
                <img src={icon2} alt="images" />
              </div>
              <div className="icon3 ani6">
                <img src={icon3} alt="images" />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 wow fadeInRight">
            <div className="wd-review-job contentbox1 page-text stc mx-2">
              <h3>{t("about.header.titleMain")}</h3>
              <p>{t("about.header.subtitleMain")}</p>
              <h6
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  marginTop: "32px",
                  marginBottom: "8px",
                }}
              >
                {t("about.header.title")}
              </h6>
              <p>{t("about.header.subtitle")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Review;
