import React, { useState } from "react";

import Header2 from "../components/header/Header2";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import PasswordChange from "../components/passwordChange";

const ChagePasswordPage = () => {
  const [isShowMobile, setShowMobile] = useState(false);

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };
  return (
    <>
      <Header2 clname="actPage5" handleMobile={handleMobile} />
      <PasswordChange />
      <Footer />
      <Gotop />
    </>
  );
};

export default ChagePasswordPage;
