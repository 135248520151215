const dataFaq = [
  {
    id: 1,
    show: "show",
    question: "faq.question1.question",
    answer: "faq.question1.answer",
  },
  {
    id: 2,
    question: "faq.question2.question",
    answer: "faq.question2.answer",
  },
  {
    id: 3,
    question: "faq.question3.question",
    answer: "faq.question3.answer",
  },
  // {
  //   id: 4,
  //   question: "faq.question4.question",
  //   answer: "faq.question4.answer",
  // },
  {
    id: 5,
    question: "faq.question5.question",
    answer: "faq.question5.answer",
  },
  {
    id: 6,
    question: "faq.question6.question",
    answer: "faq.question6.answer",
  },
];
export default dataFaq;
