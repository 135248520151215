/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  // Routes,
  // Route,
  useRoutes,
} from "react-router-dom";
import { getCookies } from "./hooks/cookie";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home_v9 from "./pages/Home_v9";
import AboutUs from "./pages/AboutUs";
import Faqs from "./pages/Faqs";
import Termsofuse from "./pages/Termsofuse";
import Pricing from "./pages/Pricing";
import Login from "./pages/Login";
import CreateAccount from "./pages/CreateAccount";
import ContactUs from "./pages/ContactUs";
import ScrollToTop from "./ScrollToTop";
import Preloader from "./components/preloader";
import Profil from "./pages/Profil";
import ChangePassword from "./pages/ChagePassword";
import NotFound from "./pages/NotFound";
import "./assets/css/styles.css";

import en from "./locales/en";
import uz from "./locales/uz";
import ru from "./locales/ru";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en,
      },
      uz: {
        translation: uz,
      },
      ru: {
        translation: ru,
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const App = () => {
  let profilPage = getCookies("token")
    ? { path: "/profile", element: <Profil /> }
    : {};
  let loginPage = !getCookies("token")
    ? { path: "/login", element: <Login /> }
    : {};
  let changePassword = getCookies("token")
    ? { path: "/change-password", element: <ChangePassword /> }
    : {};
  let routes = useRoutes([
    { path: "/", element: <Home_v9 /> },
    { path: "/aboutus", element: <AboutUs /> },
    { path: "/faqs", element: <Faqs /> },
    { path: "/termsofuse", element: <Termsofuse /> },
    { path: "/pricing", element: <Pricing /> },
    { path: "/createaccount", element: <CreateAccount /> },
    { path: "/contactus", element: <ContactUs /> },
    changePassword,
    loginPage,
    profilPage,
    { path: "*", element: <NotFound /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);

  library.add(fas, faTwitter, faFontAwesome);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {!loading ? (
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AppWrapper;
