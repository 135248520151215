import React, { useState, useEffect } from "react";
import Header03 from "../components/header/Header03";
import Banner09 from "../components/banner/Banner09";
import Counter from "../components/couter";
import Category from "../components/category";
import dataCate from "../assets/fakeData/dataCategory";
import Box09 from "../components/boxicon/Box09";
import ReviewJob09 from "../components/jobs/ReviewJob09";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
// import { Link } from "react-router-dom";
// import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import { Collapse } from "react-collapse";
// import logo from "../assets/images/logo-vertical.png";
// import JobStyle4 from "../components/jobs/JobStyle4";
import { useTranslation } from "react-i18next";

Home_v9.propTypes = {};

function Home_v9() {
  const { t } = useTranslation();
  const [isShowMobile, setShowMobile] = useState(false);

  // const [toggle, setToggle] = useState({
  //   key: "",
  //   status: false,
  // });
  // const handleToggle = (key) => {
  //   if (toggle.key === key) {
  //     setToggle({
  //       status: false,
  //     });
  //   } else {
  //     setToggle({
  //       status: true,
  //       key,
  //     });
  //   }
  // };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
  }, []);

  // box09 information
  const box09Info = {
    boxTitle: t("home.whyChooseEdu.title"),
    infoOne: {
      title: t("home.whyChooseEdu.box1.title"),
      subtitle: t("home.whyChooseEdu.box1.subtitle"),
    },
    infoTwo: {
      title: t("home.whyChooseEdu.box2.title"),
      subtitle: t("home.whyChooseEdu.box2.subtitle"),
    },
    infoThere: {
      title: t("home.whyChooseEdu.box3.title"),
      subtitle: t("home.whyChooseEdu.box3.subtitle"),
    },
  };
  return (
    <>
      <Header03 clname="act1" handleMobile={handleMobile} />
      <Banner09 />
      <Counter />

      <ReviewJob09 className="over-flow-hidden review-job-section-five" />
      {/* <GetApp className="bg-get-app" /> */}
      {/* <Partner data={dataPartner} /> */}
      <Box09 data={box09Info} />
      <Category data={dataCate} className="job-category-section" />

      <Footer />
      <Gotop />
    </>
  );
}

export default Home_v9;
