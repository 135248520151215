import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import "./style.css";

const StudentChangePassword = (prop) => {
  const { submit, setData, data } = prop.data;

  const { t } = useTranslation();
  const [showPass, setShowPass] = useState(false);
  const [showPassNew, setShowPassNew] = useState(false);

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
  });

  return (
    <div className="form-candidate">
      <form onSubmit={handleSubmit(submit)}>
        <div className="ip">
          <label>{t("changePassword.userNameTitle")}</label>
          <input
            {...register("email", {
              required: t("changePassword.userNameTitleError"),
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Emailni to'g'ri kiriting",
              },
            })}
            name="email"
            type="text"
            placeholder={t("changePassword.userName")}
            value={data.email || ""}
            onChange={({ target: { value } }) =>
              setData({ ...data, email: value })
            }
          />
          <div style={{ color: "red" }}>
            {errors?.email && <span>{errors?.email.message || ""}</span>}
          </div>
        </div>
        <div className="ip">
          <lable>{t("changePassword.currentPassword")}</lable>
          <div className="inputs-group auth-pass-inputgroup">
            <input
              {...register("currentPassword", {
                required: t("changePassword.passwordError"),
              })}
              name="currentPassword"
              type={showPass ? "text" : "password"}
              className="input-form password-input"
              placeholder={t("changePassword.currentPassword")}
              id="password-input"
              value={data?.currentPassword || ""}
              onChange={({ target: { value } }) => {
                setData({ ...data, currentPassword: value });
              }}
            />
            <Link
              className={`password-addon ${
                showPass ? "icon-eye" : "icon-eye-off"
              }`}
              id="password-addon"
              onClick={() => setShowPass(!showPass)}
            />
          </div>
          <div style={{ color: "red" }}>
            {errors?.currentPassword && (
              <span>{errors?.currentPassword.message || ""}</span>
            )}
          </div>
        </div>
        <div className="ip">
          <label>{t("changePassword.newPassword")}</label>
          <div className="inputs-group auth-pass-inputgroup">
            <input
              {...register("newPassword", {
                required: t("changePassword.passwordError"),
              })}
              name="newPassword"
              type={showPassNew ? "text" : "password"}
              className="input-form password-input"
              placeholder={t("changePassword.newPassword")}
              id="password-input"
              value={data?.newPassword || ""}
              onChange={({ target: { value } }) => {
                setData({ ...data, newPassword: value });
              }}
            />
            <Link
              className={`password-addon ${
                showPassNew ? "icon-eye" : "icon-eye-off"
              }`}
              id="password-addon"
              onClick={() => setShowPassNew(!showPassNew)}
            />
          </div>
          <div style={{ color: "red" }}>
            {errors?.newPassword && (
              <span>{errors?.newPassword.message || ""}</span>
            )}
          </div>
        </div>
        <button
          className={`${isSubmitting && "student-form_disabled"}`}
          disabled={isSubmitting}
        >
          {t("changePassword.button")}
        </button>
      </form>
    </div>
  );
};

export default StudentChangePassword;
