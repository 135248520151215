import React from "react";
// import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

ReviewJob09.propTypes = {};

function ReviewJob09({ className }) {
  const { t } = useTranslation();

  return (
    <section className={className}>
      <div className="tf-container">
        <div className="tf-title style-2">
          <div className="group-title">
            <h1>{t("home.roadmap.title")}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 wow fadeInLeft">
            <div className="wd-review-job pt9">
              <ul className="list-review2">
                <li className="active">
                  <h6>{t("home.roadmap.stage0.title")}</h6>
                  <p>{t("home.roadmap.stage0.subtitle1")}</p>
                  <p>{t("home.roadmap.stage0.subtitle2")}</p>
                </li>
                <li>
                  <h6>{t("home.roadmap.stage1.title")}</h6>
                  <p>{t("home.roadmap.stage1.subtitle")}</p>
                </li>
                <li>
                  <h6>{t("home.roadmap.stage2.title")}</h6>
                  <p>{t("home.roadmap.stage2.subtitle")}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 ">
            <div className="wd-review-job widget-counter">
              <div className="thumb3">
                {/* <div className="group-user">
                  <div className="user-box">
                    <img
                      src={require("../../assets/images/review/user11.png")}
                      alt="images"
                    />
                    <img
                      src={require("../../assets/images/review/user22.png")}
                      alt="images"
                    />
                    <img
                      src={require("../../assets/images/review/user33.png")}
                      alt="images"
                    />
                    <img
                      src={require("../../assets/images/review/user44.png")}
                      alt="images"
                    />
                  </div>
                  <div className="content">
                    <h6 className="wrap-counter">
                      <span
                        className="counter-number"
                        data-speed="2000"
                        data-to="4800"
                      ></span>
                      <span>200+</span>
                    </h6>
                    <p>{t("home.roadmap.happyPartners")}</p>
                  </div>
                </div> */}
                <div>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "58px",
                      borderRadius: "7px",
                    }}
                    src={require("../../assets/images/roadmap-img.jpg")}
                    alt="images"
                  />
                </div>
                <div className="shape ani7">
                  <img
                    src={require("../../assets/images/review/shape2.png")}
                    alt="images"
                  />
                </div>
                <div className="icon1 ani7">
                  <img
                    src={require("../../assets/images/review/icon11.png")}
                    alt="images"
                  />
                </div>
                <div className="icon2 ani4">
                  <img
                    src={require("../../assets/images/review/icon22.png")}
                    alt="images"
                  />
                </div>
                {/* <div className="icon3 ani6">
                  <img
                    src={require("../../assets/images/review/icon33.png")}
                    alt="images"
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 wow fadeInBottom">
            <div className="wd-review-job pt9">
              <ul className="list-review2">
                <li>
                  <h6>{t("home.roadmap.stage3.title")} </h6>
                  <p>{t("home.roadmap.stage3.subtitle")}</p>
                </li>
                <li>
                  <h6>{t("home.roadmap.finalWinners.title")}</h6>
                  <p>{t("home.roadmap.finalWinners.subtitle1")}</p>

                  {/* <p>{t("home.roadmap.finalWinners.grades1and2")}</p>
                  <p>{t("home.roadmap.finalWinners.grades3and4")}</p>
                  <p>{t("home.roadmap.finalWinners.grades5")}</p>
                  <p>{t("home.roadmap.finalWinners.grades6and7")}</p>
                  <p>{t("home.roadmap.finalWinners.grades8")}</p> */}
                  <p>{t("home.roadmap.finalWinners.grades9")}</p>
                  <p>{t("home.roadmap.finalWinners.grades10")}</p>
                  <p>{t("home.roadmap.finalWinners.grades11")}</p>

                  <p>{t("home.roadmap.finalWinners.subtitle2")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReviewJob09;
