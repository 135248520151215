export function setCookies(cName, cValue) {
  let date = new Date();
  date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}
export function getCookies(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}
export function eraseCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}
