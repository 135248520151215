const contacts = {
  phone: "+998 71 230-01-03",
  email: "exams@edu-action.uz",
  telegram: "https://t.me/Edu_Exams",
  instagram: "https://instagram.com/edu_exams",
  // facebook: "https://www.facebook.com/celtauzbekistan",
  youtube: "https://www.youtube.com/@EduActionCentre",
};

const menuItems = [
  {
    id: 1,
    name: "home",
    path: "/",
    hidden: false,
  },
  {
    id: 2,
    name: "aboutUs",
    path: "/aboutus",
    hidden: false,
  },
  {
    id: 3,
    name: "faqs",
    path: "/faqs",
    hidden: false,
  },
  {
    id: 4,
    name: "contactUs",
    path: "/contactus",
    hidden: false,
  },
  {
    id: 5,
    name: "profile",
    path: "/profile",
    hidden: true,
  },
];

export { contacts, menuItems };
