import React from "react";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";
// import CountUp from "react-countup";
// import { Waypoint } from "react-waypoint";

Counter.propTypes = {};

function Counter() {
  const { t } = useTranslation();
  // const [viewPortEntered, setViewPortEntered] = useState(false);

  // const onVWEnter = () => {
  //   setViewPortEntered(true);
  // };
  return (
    <section className="mt-2">
      <div className="bg-pri1 count-spacing">
        <div className="tf-container">
          <div className="row align-item-center">
            <h2 className="title-ban">{t("home.olimpiad.title")}</h2>
            <p className="description-ban">{t("home.olimpiad.subtitle")}</p>
            {/* <div className="col-lg-3 col-md-6 wow fadeInUp">
              <div className="wd-counter style-light widget-counter">
                <div className="inner wrap-counter">
                  <Waypoint onEnter={onVWEnter}>
                    <h2>
                      {viewPortEntered && (
                        <CountUp
                          className="number"
                          end={20}
                          suffix="K+"
                          duration={3}
                        />
                      )}
                    </h2>
                  </Waypoint>
                </div>
                <p className="description">Student registered</p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="wd-counter style-light widget-counter">
                <div className="inner wrap-counter">
                  <Waypoint onEnter={onVWEnter}>
                    <h2>
                      {viewPortEntered && (
                        <CountUp
                          className="number"
                          end={177}
                          suffix="+"
                          duration={1}
                        />
                      )}
                    </h2>
                  </Waypoint>
                </div>
                <p className="description">New Applicants!</p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="wd-counter style-light widget-counter">
                <div className="inner wrap-counter">
                  <Waypoint onEnter={onVWEnter}>
                    <h2>
                      {viewPortEntered && (
                        <CountUp
                          className="number"
                          end={4800}
                          suffix="+"
                          duration={1}
                        />
                      )}
                    </h2>
                  </Waypoint>
                </div>
                <p className="description">Tests</p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="wd-counter style-light widget-counter">
                <div className="inner wrap-counter">
                  <Waypoint onEnter={onVWEnter}>
                    <h2>
                      {viewPortEntered && (
                        <CountUp className="number" end={50} duration={3} />
                      )}
                    </h2>
                  </Waypoint>
                </div>
                <p className="description">Awards</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Counter;
