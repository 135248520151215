import React, { useState } from "react";
import { Link } from "react-router-dom";

import enFlag from "./flags/us.jpg";
import uzFlag from "./flags/uz.jpg";
import russiaFlag from "./flags/russia.jpg";
import i18next from "i18next";
import "./style.css";

// get the languages
const Languages = {
  uz: {
    name: "O'zbekcha",
    slug: "uz",
    flag: uzFlag,
  },
  en: {
    name: "English",
    slug: "en",
    flag: enFlag,
  },
  ru: {
    name: "Русский",
    slug: "ru",
    flag: russiaFlag,
  },
};
const LanguageDropdown = () => {
  const [currentLang, setCurrentLang] = useState(Languages[i18next.language]);

  return (
    <div className="categories">
      <div className="wrapper-lang">
        <Link to="#">
          <img
            src={currentLang.flag}
            alt={currentLang.name}
            className="language-imgs"
          />{" "}
          <span className="language-txt">{currentLang.name}</span>
        </Link>
      </div>
      <div className="sub-categorie">
        <ul className="pop-up">
          {(Object.values(Languages) || []).map((lang, i) => {
            return (
              <li
                // to="#"
                onClick={() => {
                  console.log(lang.slug);
                  i18next.changeLanguage(lang.slug);
                  setCurrentLang(lang);
                }}
                className="dropdown-item notify-item c-pointer"
                key={i + "-lang"}
              >
                <Link to="#">
                  <img
                    src={lang.flag}
                    alt={lang.name}
                    className="language-imgs"
                    height="8"
                  />{" "}
                  {lang.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LanguageDropdown;
