import React from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import SelectLocation from "../dropdown";

Banner09.propTypes = {};

function Banner09() {
  const { t } = useTranslation();
  return (
    <section
      // style={{ paddingTop: "120px" }}
      className="tf-slider sl7 over-flow-hidden"
    >
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="content wow fadeInUp">
              <div className="heading p-3">
                <h4>{t("home.header.title")}</h4>
                <br />
                <p>
                  <b> {t("home.header.miniTitle")}</b>
                </p>
                <p>{t("home.header.subtitle")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="wd-review-job widget-counter sl7">
              <div className="thumb3">
                <div className="trader-box sl7">
                  <div className="content">
                    <h3 className="number wrap-counter">
                      <CountUp
                        className="number"
                        end={20}
                        suffix="K+"
                        duration={3}
                      />
                    </h3>
                    <div className="des">{t("home.header.studentImg")}</div>
                  </div>
                  <div className="shape ani7">
                    <img
                      src={require("../../assets/images/review/shape6.png")}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="group-user">
                  <div className="user-box">
                    {/* <img
                      src={require("../../assets/images/review/bran4.jpg")}
                      alt="images"
                    />
                    <img
                      src={require("../../assets/images/review/bran1.jpg")}
                      alt="images"
                    />
                    <img
                      src={require("../../assets/images/review/bran2.jpg")}
                      alt="images"
                    />
                    <img
                      src={require("../../assets/images/review/bran3.jpg")}
                      alt="images"
                    /> */}
                  </div>
                  <div className="content">
                    <h6 className="wrap-counter text-pri">
                      <span
                        className="counter-number"
                        data-speed="2000"
                        data-to="4800"
                      >
                        4800
                      </span>
                      <span>+</span>
                    </h6>
                    <p>{t("home.header.testImg")}</p>
                  </div>
                </div>
                <div className="group-thumb">
                  <img
                    className="home-header_img"
                    src={require("../../assets/images/about1.jpg")}
                    alt="images"
                  />
                  <div className="shape-thumb ani8">
                    <img
                      src={require("../../assets/images/review/shape5.png")}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="icon1 ani7">
                  <img
                    src={require("../../assets/images/review/icon11.png")}
                    alt="images"
                  />
                </div>
                <div className="icon2 ani4">
                  <img
                    src={require("../../assets/images/review/icon22.png")}
                    alt="images"
                  />
                </div>
                <div className="icon3 ani5">
                  <img
                    src={require("../../assets/images/review/icon3.png")}
                    alt="images"
                  />
                </div>
                <div className="icon4 ani6">
                  <img
                    src={require("../../assets/images/review/icon2.png")}
                    alt="images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner09;
