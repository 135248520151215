import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import { getCookies } from "./cookie";

export const useUser = () => {
  const token = getCookies("token");

  const getPayloadFromToken = (token) => {
    console.log(jwtDecode(token));
    return jwtDecode(token);
  };

  const [user, setUser] = useState(() => {
    if (!token) return null;

    return getPayloadFromToken(token);
  });

  useEffect(() => {
    if (!token) {
      setUser(null);
    } else {
      setUser(getPayloadFromToken(token));
    }
  }, [token]);

  return user;
};
