import { create } from "apisauce";
import { getCookies } from "../hooks/cookie";

const apiClient = create({
  baseURL: "https://api.edu-action.uz/v1",
  // baseURL: "http://localhost:3000/dev/v1",
});

export const register = (data) => apiClient.post("/enquiry/register", data);
export const registerSchool = (data) =>
  apiClient.post("/school/register", data);

export const login = (data) => apiClient.post("/enquiry/login", data);
export const loginSchool = (data) => apiClient.post("/school/login", data);

export const changePassword = (data) =>
  apiClient.post("/enquiry/change-password", data);
export const changePasswordSchool = (data) =>
  apiClient.post("/school/change-password", data);

export const getDetails = (id) => apiClient.get(`/enquiry/details/${id}`);
export const getDetailsSchool = (id) => apiClient.get(`/school/details/${id}`);

export const checkPassport = (data) => apiClient.post("/check-details", data);

export const prePayment = (data) => apiClient.post("/pre-pay", data);
export const postPayment = (data) => apiClient.post("/post-pay", data);

apiClient.addAsyncRequestTransform(async (request) => {
  const token = getCookies("token");
  if (!token) return;
  request.headers["Authorization"] = `Bearer ${token}`;
});

apiClient.addAsyncResponseTransform(async (response) => {
  console.log("response------>", response);
});

export default apiClient;
