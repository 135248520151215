const dataCate = [
  {
    id: 1,
    title: "Stage 0",
    unit: "Registration",
    active: "active",
    descriptions: [
      "Teachers: Register your school and provide contact details.",
      "Students: Choose or enter your school to register.",
    ],
  },
  {
    id: 2,
    title: "Stage 1",
    unit: "Online Test",
    descriptions: [
      "In this stage students will take an online Cambridge English Placement test and only the first 100 participants from each grade will move to the Stage 2. ",
    ],
  },

  {
    id: 3,
    title: "Stage 2",
    unit: "Linguaskill Listening and Reading",
    descriptions: [
      "A selected group of 300 participants will move on to demonstrate their listening and reading skills through the Linguaskill test. Participants will be divided into groups according to their grades (9-10-11), with their results calculated separately to ensure a fair and level playing field.",
    ],
  },
  {
    id: 4,
    title: "Stage 3",
    unit: "Linguaskill Writing and Speaking ",
    descriptions: [
      "The competition intensifies as students tackle the Linguaskill writing and speaking tasks. Scores will be meticulously calculated according to grades (9-10-11), leading to the identification of 3 exceptional winners from each grade who have demonstrated outstanding linguistic abilities.",
    ],
  },
  {
    id: 5,
    title: "Final",
    unit: "Winners",
    descriptions: [
      "The Elevate English Challenge will culminate in the celebration of 24 winners, distributed across various grade levels, each awarded for their excellence in the English language:",
    ],
    bulletPoints: [
      "Grades 1-2: Top 3 Places",
      "Grades 3-4: Top 3 Places",
      "Grades 5: Top 3 Places",
      "Grades 6-7: Top 3 Places",
      "Grades 8: Top 3 Places",
      "Grades 9: Top 3 Places",
      "Grades 10: Top 3 Places",
      "Grades 11: Top 3 Places",
    ],
  },
  {
    id: 6,
    title: "Final Round 4",
    unit: "Primary/Face to Face",
  },
  {
    id: 7,
    title: "Round 4",
    unit: "Secondary /Face to Face/online",
  },
  {
    id: 8,
    title: "Final Round 5",
    unit: "Secondary /Face to Face/online  ",
  },
];

export default dataCate;
