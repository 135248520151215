import React from "react";
import "./style.css";

import userIcon from "../../../assets/images/user.png";
import eduWork from "../../../assets/images/education-work.png";
import { Accordion } from "react-bootstrap-accordion";
import { useState } from "react";
import moment from "moment";
import useApi from "../../../hooks/use-api";
import { checkPassport, postPayment, prePayment } from "../../../api/client";
import { toast } from "react-toastify";

import Cards from "@hasanboy93/react-cards-uz";
import "@hasanboy93/react-cards-uz/dist/es/styles-compiled.css";
import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatOtp,
  formatPhone,
} from "../../../utils/formatCardValues";
import { useEffect } from "react";
import { currencyFormatter } from "../../../utils/formatter";
import { Link } from "react-router-dom";

const ApplicationInfo = ({
  roundsInfo,
  current = "Round 0",
  details,
  setDetails,
  loading,
}) => {
  const [passportId, setPassportId] = useState(null);
  const [checkLoading, setCheckLoading] = useState(false);
  const [dob, setDob] = useState(
    moment(details?.dob, "DD/MM/YYYY").format("YYYY-MM-DD")
  );
  const [isPost, setIsPost] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
    phone: "",
    otp: "",
  });
  const [transactionId, setTransactionId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const checkPassportApi = useApi(checkPassport, "data");
  const prePaymentApi = useApi(prePayment, "data");
  const postPaymentApi = useApi(postPayment, "data");

  const handleInputFocus = (evt) => {
    setCardDetails((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const handleInputChange = (evt) => {
    let { name, value } = evt.target;
    let newValue;
    if (name === "number") {
      value = formatCreditCardNumber(value);
    } else if (name === "expiry") {
      value = formatExpirationDate(value);
    } else if (name === "phone") {
      value = formatPhone(value);
    } else if (name === "otp") {
      value = formatOtp(value);
    }
    setCardDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handlePrePayment = async (e, price) => {
    e.preventDefault();
    // console.log(cardDetails);
    let cardNumber = cardDetails.number.replace(/\s/g, "");
    if (cardNumber.length !== 16) {
      return toast.error(`Please enter correct 16-digit card number`);
    }
    if (cardDetails.phone.length !== 9) {
      return toast.error(`Please enter correct 9-digit phone number`);
    }
    let year = cardDetails.expiry.split("/")[1];
    let month = cardDetails.expiry.split("/")[0];
    try {
      let result = await prePaymentApi.request({
        phoneNumber: cardDetails.phone,
        cardNumber: cardNumber,
        cardExpire: `${year}${month}`,
        enquiryId: `${details.pinpp}:${details.passportId}:${details.fullName}:null:Tashkent:Olympiad}`,
        amount: price,
      });
      console.log(result);
      if (result.status === 200) {
        if (!result.data?.success) {
          toast.error(result.data?.description);
        } else {
          setTransactionId(result.data?.transactionId);
          setIsPost(true);
        }
      } else {
        setErrorMessage(result.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.description || "Please try again.");
    }
  };

  const handlePostPayment = async (e, roundTitle) => {
    e.preventDefault();
    // console.log(cardDetails);

    if (cardDetails.otp === "") {
      return toast.error(
        `Please enter one-time code that was sent to your phone number`
      );
    }
    try {
      let result = await postPaymentApi.request({
        transactionId,
        otp: cardDetails.otp,
        enquiryId: details.enquiryId,
        examDate: moment().format("DD/MM/YYYY"),
        ...details,
        exam: "Olympiad",
        module: roundTitle,
        candidateName: `${details.fullName}`,
        candidatePassport: details.passportId,
        candidateDob: moment(details.dob, "YYYY-MM-DD").format("DD/MM/YYYY"),
      });

      if (result.status === 200) {
        // setShowSuccess(true);
        // setIsPost(false);
        // setShowPaymentForm(false);
        // setTransactionDetails(result.data.transaction);
        // navigate(`/verification/${result.data.transaction.transactionId}`);
      } else {
        setErrorMessage(result.data?.message);
        toast.error(result.data?.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.data?.description || "Error, try again");
    }
  };

  const handlePassportCheck = async () => {
    setCheckLoading(true);
    let response = await checkPassportApi.request({
      docNumber: passportId,
      dob,
      enquiryId: details._id,
    });
    if (response.status === 200) {
      setDetails({
        ...details,
        ...response?.data,
      });
      toast.success("Your personal details have been updated successfully");
    } else if (response.status === 400) {
      toast.error("Check your passport details");
    } else {
      toast.error("Server error. Try again later");
    }
    setCheckLoading(false);
  };

  useEffect(() => {
    setDob(moment(details?.dob, "DD/MM/YYYY").format("YYYY-MM-DD"));
  }, [details?.dob]);

  return (
    <>
      {!details.pinpp && (
        <div className="form-candidate mt-2 mb-2">
          <div className="row">
            <div className="col-lg-6">
              <div className="ip out s1">
                <label htmlFor="pasport">Pasport/ID No</label>
                <input
                  name="pasport"
                  id="pasport"
                  type="text"
                  placeholder="AF1234567"
                  value={passportId}
                  onChange={(e) => setPassportId(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ip out s1">
                <label htmlFor="pasport">Date of Birth</label>
                <input
                  name="dob"
                  id="dob"
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <button
                type="button"
                onClick={handlePassportCheck}
                disabled={checkLoading}
              >
                Proceed
              </button>
            </div>
          </div>
          <br />
        </div>
      )}
      <div className="flat-accordion">
        {details.rounds &&
          details.rounds?.map(
            ({ title, readyForPayment, paymentMade, transactionId }, index) => (
              <Accordion
                key={title}
                title={title}
                show={title === current}
                className="flat-toggle"
              >
                <div className="toggle-content">
                  <div className="mb-2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: roundsInfo[index]?.body,
                      }}
                    ></div>
                    {readyForPayment && !paymentMade && (
                      <>
                        <div className="form-candidate mb-2">
                          <div className="row">
                            <div className="mb-3 col-lg-6">
                              <h6>Payment Details</h6>
                              <br />
                              <p>
                                <strong>Exam Price</strong>:{" "}
                                {currencyFormatter(
                                  `${roundsInfo[index].priceAmount}`,
                                  "UZS"
                                )}
                                <br />
                                <strong>Processing Fee</strong>:{" "}
                                {currencyFormatter(
                                  `${
                                    Math.ceil(
                                      (roundsInfo[index].priceAmount * 100) / 99
                                    ) - roundsInfo[index].priceAmount
                                  }`,
                                  "UZS"
                                )}
                                <br />
                                <strong>Total to Pay</strong>:{" "}
                                {currencyFormatter(
                                  `${Math.ceil(
                                    (roundsInfo[index].priceAmount * 100) / 99
                                  )}`,
                                  "UZS"
                                )}
                              </p>
                            </div>
                            <div className="mb-3 col-lg-6">
                              <Cards
                                number={cardDetails.number}
                                expiry={cardDetails.expiry}
                                cvc={cardDetails.cvc}
                                name={cardDetails.name}
                                focused={cardDetails.focus}
                                placeholders={{ name: "" }}
                                acceptedCards={["uzcard", "humo"]}
                                preview={isPost}
                              />
                            </div>
                          </div>
                          <h6>Fill in the details:</h6>
                          <form>
                            <div className="row">
                              <div className="form-group mb-3 mt-4 col-lg-6">
                                <input
                                  type="tel"
                                  name="number"
                                  className="form-control"
                                  placeholder={"Card Number"}
                                  pattern="[\d| ]{16,22}"
                                  required
                                  value={cardDetails.number}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                  disabled={isPost}
                                />
                              </div>
                              <div className="form-group mb-3 mt-4 col-lg-6">
                                <input
                                  type="tel"
                                  name="expiry"
                                  className="form-control"
                                  placeholder="Valid Thru"
                                  pattern="\d\d/\d\d"
                                  required
                                  value={cardDetails.expiry}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                  disabled={isPost}
                                />
                              </div>
                              <div className="form-group mb-3 mt-4 col-lg-6">
                                <input
                                  type="tel"
                                  name="phone"
                                  className="form-control"
                                  placeholder={"Phone Number (941234567)"}
                                  title="Enter your number without 998"
                                  pattern=""
                                  required
                                  value={cardDetails.phone}
                                  onChange={handleInputChange}
                                  disabled={isPost}
                                  data-tooltip-id="phone"
                                  data-tooltip-content="Connected to your card, without 998. e.g. 943213322"
                                />
                              </div>
                              {isPost ? (
                                <div className="form-group mb-3 mt-4 col-lg-6">
                                  <input
                                    type="tel"
                                    name="otp"
                                    className="form-control"
                                    placeholder={"Enter the code from SMS"}
                                    pattern=""
                                    required
                                    value={cardDetails.otp}
                                    onChange={handleInputChange}
                                  />
                                  {/* <Tooltip id="phone" /> */}
                                </div>
                              ) : null}
                            </div>
                          </form>
                          {!isPost ? (
                            <button
                              type="submit"
                              className="btn btn-light btn-xl  mb-2"
                              onClick={(e) =>
                                handlePrePayment(
                                  e,
                                  Math.ceil(
                                    (roundsInfo[index].price * 100) / 99
                                  )
                                )
                              }
                            >
                              Proceed
                            </button>
                          ) : null}
                          {isPost ? (
                            <button
                              type="submit"
                              className="btn btn-light btn-xl  mb-2"
                              onClick={(e) =>
                                handlePostPayment(e, roundsInfo[index]?.title)
                              }
                            >
                              Pay
                            </button>
                          ) : null}
                        </div>
                      </>
                    )}

                    {paymentMade && (
                      <>
                        <br />
                        <p>
                          You have successfully paid the amount. You can check
                          the verification by pressing the button below:
                        </p>
                        <a
                          className="btn btn-primary btn-xl  mb-2"
                          href={`https://payment.edu-action.uk/verification/${transactionId}`}
                          target="_blank"
                        >
                          Verification
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </Accordion>
            )
          )}
      </div>
    </>
  );
};

export default ApplicationInfo;
