/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import logo from "../../assets/images/logo-vertical.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../LanguageDropdown";
import MobileMenu from "./MobileMenu";
import { menuItems } from "../../utils/constants";
import { getCookies } from "../../hooks/cookie";

Header03.propTypes = {};

function Header03({ clname = "", handleMobile }) {
  const { t } = useTranslation();

  const location = useLocation();
  const [pathName, setPathName] = useState(null);

  useEffect(() => {
    if (location) {
      let tmp = location.pathname.slice(
        location.pathname.lastIndexOf("/"),
        location.pathname.length
      );
      setPathName(tmp);
    }
  }, [location]);

  return (
    <>
      <MobileMenu handleMobile={handleMobile} />

      <header id="header" className="header header-style2">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div className="sticky-area-wrap">
                <div className="header-ct-center">
                  <div id="logo" className="logo">
                    <Link to="/">
                      <img className="site-logo" src={logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="nav-wrap">
                    <nav id="main-nav" className="main-nav">
                      <ul id="menu-primary-menu" className="menu">
                        {menuItems.map(({ id, name, path, hidden }) =>
                          !hidden || getCookies("token") ? (
                            <li
                              key={id}
                              className={`menu-item ${
                                path === pathName ? "act1" : ""
                              }`}
                            >
                              <Link to={path}>{t(`navbar.${name}`)}</Link>
                            </li>
                          ) : (
                            ""
                          )
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="header-ct-left">
                  <LanguageDropdown />
                </div>
                {!getCookies("token") && (
                  <div className="header-ct-right">
                    {/* <div className="header-customize-item help">
                  <Link to="/termofuse">
                    <span className="icon-help-circle"></span>
                  </Link>
                </div> */}
                    {/* <div className="header-customize-item bell">
                  <span className="icon-bell"></span>
                  <div className="sub-notification">
                    <div className="sub-notification-heading">
                      <div className="sub-notification-title">Notification</div>
                      <span>5 New</span>
                    </div>
                    <div className="sub-notification-content">
                      <div className="sub-notification-item icon-plus">
                        <div className="time">Last day</div>
                        <div className="content">
                          Hello <span className="name">Round 1</span> is
                          <span className="status"> Success</span>
                        </div>
                      </div>
                      <div className="sub-notification-item icon-plus">
                        <div className="time">5 Day ago</div>
                        <div className="content">
                          A new application is submitted
                          <span className="name"> Round 1 </span> by
                          <span className="name"> Azizbek</span>
                        </div>
                      </div>
                    </div>
                    <div className="sub-notification-button">
                      <Link to="#">Read All</Link>
                    </div>
                  </div>
                </div> */}

                    <div className="header-customize-item login">
                      <Link to="/login">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                              stroke="#121212"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                              stroke="#121212"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Link>
                      {/* <ul className="item-login">
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                    <li>
                      <Link to="/createaccount">Register</Link>
                    </li>
                  </ul> */}
                    </div>
                  </div>
                )}

                <div className="nav-filter" onClick={handleMobile}>
                  <div className="nav-mobile">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header03;
