import React from "react";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

Box09.propTypes = {};

function Box09(prop) {
  let { boxTitle, infoOne, infoTwo, infoThere } = prop.data;
  return (
    <section className="wd-iconbox flat-row background3 style-2">
      <div className="tf-container">
        <div className="title-iconbox style-2">
          <h1>{boxTitle ? boxTitle : "No data"}</h1>
          {/* <p>integer vitae justo eget magna fermentum iaculis eu non diam</p> */}
        </div>
        <div className="row rg-st1">
          <div className="colum4 wow fadeInUp" data-wow-delay="0.4s">
            <div className="tf-iconbox style-2">
              <div className="box-header">
                <div className="icon bg-white">
                  <svg
                    width="52"
                    height="53"
                    viewBox="0 0 52 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.5027 22.0163C33.3592 24.851 33.3592 29.4689 30.5027 32.3037C27.6462 35.1384 23.0301 35.1384 20.1736 32.3037C17.3171 29.4689 17.3171 24.851 20.1736 22.0163C22.0703 20.1189 24.7669 19.5016 27.1892 20.1417"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M36.5347 16.0277C42.7047 22.1772 42.7047 32.1445 36.5347 38.2941C30.3647 44.4436 20.3327 44.4436 14.1627 38.2941C7.99265 32.1445 7.99265 22.1772 14.1627 16.0277C19.4415 10.7697 27.531 10.0153 33.6325 13.7644"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M35.6892 5.21402C26.6627 1.00763 15.5795 2.60789 8.1298 10.0148C-1.3766 19.4792 -1.3766 34.8188 8.1298 44.2831C17.6362 53.7475 33.0612 53.7475 42.5676 44.2831C50.9543 35.9161 51.9369 22.9769 45.5156 13.5582"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M38.019 14.5413L38.796 13.7869"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M36.5348 16.0269L30.502 22.0164"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M30.5006 22.0168L25.0161 27.4806"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M38.7979 13.7876L41.2659 11.3186"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M36.5347 16.0274L38.02 14.5415"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M37.9067 13.6721L38.0211 14.5408"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M44.5107 8.09529V6.79224"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M40.6021 5.48845L44.5097 1.625V6.79154"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M39.0029 7.11152L40.6026 5.48838"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M39.0027 7.11084L37.3574 8.73395L37.9058 13.6719"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M38.019 14.5416L43.8463 15.2046L45.4916 13.5586"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M45.8133 8.09473H44.5107"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M45.4922 13.5585L50.9995 8.09473H45.8121"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M37.9067 13.6721L38.7979 13.7864"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="box-content">
                <h1 className="box-title">
                  <Link to="#" className="h3">
                    {infoOne?.title ? infoOne?.title : "No data"}
                  </Link>
                </h1>
                <p style={{ textTransform: "none", fontSize: "18px" }}>
                  {infoOne?.subtitle ? infoOne?.subtitle : `No data`}
                </p>
              </div>
            </div>
          </div>
          <div className="colum4 wow fadeInUp" data-wow-delay="0.3s">
            <div className="tf-iconbox style-2">
              <div className="box-header">
                <div className="icon bg-white">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="100" height="100" rx="50" fill="white" />
                    <path
                      d="M48.7688 46.2566C51.0321 46.2566 52.8668 44.4211 52.8668 42.157C52.8668 39.8929 51.0321 38.0575 48.7688 38.0575C46.5056 38.0575 44.6709 39.8929 44.6709 42.157C44.6709 44.4211 46.5056 46.2566 48.7688 46.2566Z"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M42.2319 55.7059V52.8157C42.2319 47.8963 45.2234 46.2565 48.133 46.2565H49.4443C52.3334 46.2565 55.3453 47.8963 55.3453 52.8157V55.7059"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M31.9462 38.0985C31.8028 38.078 31.6594 38.0575 31.5159 38.0575C29.2621 38.0575 27.4385 39.9023 27.4385 42.157C27.4385 43.8378 28.463 45.2932 29.8972 45.9286"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M32.7661 46.0517C34.4258 45.5187 35.6347 44.0019 35.6347 42.1571C35.6347 41.0707 35.2044 40.1073 34.5282 39.3694"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M38.1139 55.7061V52.8159C38.1139 48.2244 35.4708 46.4821 32.7661 46.2771"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.8975 46.3181C27.3568 46.6666 25.0005 48.4499 25.0005 52.8159V55.7061"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M64.7709 46.0517C63.1112 45.5187 61.9023 44.0019 61.9023 42.1571C61.9023 41.0707 62.3326 40.1073 63.0088 39.3694"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M59.4233 55.7061V52.8159C59.4233 48.2244 62.0665 46.4821 64.7711 46.2771"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M67.7422 46.2566C67.7422 46.2361 67.7422 46.195 67.7422 46.1744"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M31.9469 38.0985C30.738 40.4557 30.0004 43.0999 29.8774 45.9286"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M65.6313 38.2419C66.8402 40.6401 67.5369 43.3253 67.6189 46.1745"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M31.9873 38.0985C35.1017 32.0107 41.4535 27.8291 48.7684 27.8291C56.1447 27.8291 62.5374 32.0722 65.6314 38.242"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.9175 46.3179C29.9175 46.4409 29.9175 46.5639 29.9175 46.6869C29.9175 57.0997 38.3592 65.5448 48.768 65.5448C59.1768 65.5448 67.6185 57.0997 67.6185 46.6869C67.6185 46.5434 67.6185 46.3999 67.598 46.2565"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.8774 46.318C29.8774 46.1745 29.8774 46.0515 29.8979 45.9081"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M32.7461 46.2772C32.7461 46.1952 32.7461 46.1132 32.7666 46.0518"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M60.4673 61.7529L70.077 71.3253C71.2039 72.4527 73.0275 72.4527 74.1544 71.3253C75.2814 70.198 75.2814 68.3942 74.1544 67.2668L64.5447 57.6944"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M65.5913 38.0985C65.7347 38.078 65.8782 38.0575 66.0216 38.0575C68.2755 38.0575 70.099 39.9023 70.099 42.157C70.099 43.8378 69.0746 45.2932 67.6403 45.9286"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M67.6396 46.3181C70.1804 46.6666 72.5367 48.4499 72.5367 52.8159V55.7061"
                      stroke="#148160"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="box-content">
                <h1 className="box-title">
                  <Link to="#" className="h3">
                    {infoTwo?.title ? infoTwo?.title : "No data"}
                  </Link>
                </h1>
                <p style={{ textTransform: "none", fontSize: "18px" }}>
                  {infoTwo?.subtitle ? infoTwo?.subtitle : `No data`}
                </p>
              </div>
            </div>
          </div>
          <div className="colum4 wow fadeInUp">
            <div className="tf-iconbox style-2">
              <div className="box-header">
                <div className="icon bg-white">
                  <svg
                    width="42"
                    height="43"
                    viewBox="0 0 42 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.1242 26.6601C12.2169 26.6601 13.9133 24.9714 13.9133 22.8883C13.9133 20.8052 12.2169 19.1165 10.1242 19.1165C8.03144 19.1165 6.33496 20.8052 6.33496 22.8883C6.33496 24.9714 8.03144 26.6601 10.1242 26.6601Z"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.59724 31.9404L10.1242 35.9951C10.5767 37.1832 11.7078 37.9753 12.9897 37.9753H18.3624C19.2484 37.9753 19.9648 37.2586 19.9648 36.3723C19.9648 35.5613 19.3616 34.8824 18.5509 34.7881L14.4036 34.2789C14.1019 34.2412 13.838 34.0337 13.7626 33.732C13.5175 32.8079 12.952 30.8277 12.3676 29.6962C11.6135 28.1874 10.8594 26.6787 8.20135 26.6787C4.78919 26.6787 4.03512 28.9418 3.28106 31.9592C2.52699 34.9767 1 41.0116 1 41.0116"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.8778 37.221L10.1426 40.9928"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.4209 37.9753H39.5511"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.452 8.55534C25.7117 8.55534 26.733 7.54212 26.733 6.29226C26.733 5.04239 25.7117 4.02917 24.452 4.02917C23.1922 4.02917 22.1709 5.04239 22.1709 6.29226C22.1709 7.54212 23.1922 8.55534 24.452 8.55534Z"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.7002 13.8358V12.9871C20.7002 9.66792 22.4346 8.55524 24.0935 8.55524H24.8476C26.5065 8.55524 28.2409 9.66792 28.2409 12.9871V13.8358"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.6504 10.0641V3.2748C11.6504 2.03011 12.6684 1.01172 13.9126 1.01172H23.7155"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.6504 10.0641V14.5902C11.6504 15.8349 12.6684 16.8533 13.9126 16.8533H28.2399L32.7643 21.3795V16.8533H35.7806C37.0248 16.8533 38.0428 15.8349 38.0428 14.5902V3.2748C38.0428 2.03011 37.0248 1.01172 35.7806 1.01172H23.7155"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.208 34.2035L25.9783 24.3969H40.3056L36.5353 34.2035H22.208Z"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M28.9951 37.9753V34.2035"
                      stroke="#14A077"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="box-content">
                <h1 className="box-title">
                  <Link to="#" className="h3">
                    {infoThere?.title ? infoThere?.title : "No data"}
                  </Link>
                </h1>
                <p style={{ textTransform: "none", fontSize: "18px" }}>
                  {infoThere?.subtitle ? infoThere?.subtitle : `No data`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Box09;
