/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { getCookies } from "../../hooks/cookie";
import { Link, useLocation } from "react-router-dom";
import LanguageDropdown from "../LanguageDropdown";
import { useTranslation } from "react-i18next";
import { contacts, menuItems } from "../../utils/constants";
import MobileMenu from "./MobileMenu";
import logo from "../../assets/images/logo-vertical.png";
// import avt from "../../assets/images/user/avatar/image-01.jpg";

Header2.propTypes = {};

function Header2({ clname = "", handleMobile }) {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);
  const location = useLocation();
  const [pathName, setPathName] = useState(null);

  useEffect(() => {
    if (location) {
      let tmp = location.pathname.slice(
        location.pathname.lastIndexOf("/"),
        location.pathname.length
      );
      setPathName(tmp);
    }
  }, [location]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  }, []);

  return (
    <>
      <MobileMenu handleMobile={handleMobile} />
      <header
        id="header"
        className={`header header-default ${scroll ? "is-fixed is-small" : ""}`}
      >
        <div className="tf-container ct2">
          <div className="row">
            <div className="col-md-12">
              <div className="sticky-area-wrap">
                <div className="header-ct-left">
                  <div id="logo" className="logo">
                    <Link to="/">
                      <img
                        className="site-logo"
                        id="trans-logo"
                        src={logo}
                        alt="Image"
                      />
                    </Link>
                  </div>
                </div>
                <div className="header-ct-center">
                  <div className="nav-wrap">
                    <nav id="main-nav" className="main-nav">
                      <ul id="menu-primary-menu" className={`menu ${clname}`}>
                        {menuItems.map((item) =>
                          !item.hidden || getCookies("token") ? (
                            <li
                              key={item.id}
                              className={`menu-item ${
                                pathName === item.path ? "act1" : ""
                              }`}
                            >
                              <Link to={item.path}>
                                {t(`navbar.${item.name}`)}
                              </Link>
                            </li>
                          ) : (
                            ""
                          )
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="header-ct-right">
                  {/* <div className="header-customize-item bell">
                  <span className="icon-bell"></span>
                  <div className="sub-notification">
                    <div className="sub-notification-heading">
                      <div className="sub-notification-title">Notification</div>
                      <span>5 New</span>
                    </div>
                    <div className="sub-notification-content">
                      <div className="sub-notification-item icon-plus">
                        <div className="time">Last day</div>
                        <div className="content">
                          Hello <span className="name">Round 1</span> is
                          <span className="status"> Success</span>
                        </div>
                      </div>
                      <div className="sub-notification-item icon-plus">
                        <div className="time">5 Day ago</div>
                        <div className="content">
                          A new application is submitted
                          <span className="name"> Round 1 </span> by
                          <span className="name"> Azizbek</span>
                        </div>
                      </div>
                    </div>
                    <div className="sub-notification-button">
                      <Link to="#">Read All</Link>
                    </div>
                  </div>
                </div> */}
                  <div className="header-ct-left">
                    <LanguageDropdown />
                    {!getCookies("token") && (
                      <div className="header-ct-right">
                        <div className="header-customize-item login">
                          <Link to="/login">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                                  stroke="#121212"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                                  stroke="#121212"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="header-customize-item account">
                    <img src={avt} alt="jobtex" />
                    <div className="name">Candidate</div>
                  </div> */}
                </div>
                <div className="nav-filter" onClick={handleMobile}>
                  <div className="nav-mobile">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header2;
