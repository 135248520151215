/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
// import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { eraseCookie } from "../../hooks/cookie";
import ApplicationInfo from "./ApplicationInfo/idex";
// import PreTestResult from "./PreTestResult";
import "./style.css";

import noImg from "../../assets/images/dashboard/image-up.jpg";
import { useUser } from "../../hooks/use-user";
import useApi from "../../hooks/use-api";
import { getDetails, getDetailsSchool } from "../../api/client";
import { useState } from "react";
import { useEffect } from "react";
import Preloader from "../preloader";
// import StudentForm from "../category/StudentForm";

const UserInfo = () => {
  const user = useUser();
  const getDetailsApi = useApi(getDetails, "data");
  const getSchoolDetailsApi = useApi(getDetailsSchool, "data");
  const [isSchool, setIsSchool] = useState(user?.contactPerson || false);
  const [details, setDetails] = useState({});
  const [rounds, setRounds] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserDetails = async (school) => {
    setLoading(true);
    let response = school
      ? await getSchoolDetailsApi.request(user._id)
      : await getDetailsApi.request(user._id);

    setDetails(response.data.details);
    setRounds(response.data.rounds);
    setLoading(false);
  };

  useEffect(() => {
    getUserDetails(isSchool);
  }, [isSchool]);
  if (loading) {
    return <Preloader />;
  } else
    return (
      <>
        <section className="account-section user-info_section">
          <div className="tf-container">
            {isSchool ? (
              <></>
            ) : (
              <div className="row">
                <div className="col-lg-3">
                  {" "}
                  <div className="user-info_wrapper">
                    <div className="user-title_info">
                      <img src={details.photo || noImg} alt="user-image" />
                      <h6>{details.fullName}</h6>
                      <p>{details.schoolNo}</p>
                    </div>

                    <div className="user-subtitle_info">
                      <h6>About student:</h6>
                      <ul>
                        <li>
                          <span>Enquiry ID:</span> {details.enquiryId}
                        </li>
                        <li>
                          <span>Mobile:</span> {details.phone}
                        </li>
                        <li>
                          <span>Email:</span> {details.email}
                        </li>
                        <li>
                          <span>DOB:</span> {details.dob}
                        </li>
                        {details.passportId && (
                          <li>
                            <span>Pasport / ID No:</span> {details.passportId}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="change-password_navigate">
                    <Link to="/change-password">Change Password</Link>
                    <Link to="/" onClick={() => eraseCookie("token")}>
                      Logout
                    </Link>
                  </div>
                </div>
                <div className="col-lg-9">
                  <ApplicationInfo
                    details={details}
                    setDetails={setDetails}
                    roundsInfo={rounds}
                    loading={loading}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
};

export default UserInfo;
