const en = {
  navbar: {
    home: "Home",
    aboutUs: "About us",
    faqs: "FAQs",
    contactUs: "Contact us",
    profil: "Profil",
    login: "Login",
    register: "Register",
    logout: "Logout",
    profile: "Profile",
    settings: "Settings",
  },
  footer: {
    followUs: "Follow Us:",
    linksTitle: "Quick Links",
    aboutUs: "About us",
    faqs: "FAQs",
    contactUs: "Contact us",
    phoneInfo: "Need help?",
    addOlimpiad: "Join the English Olympiad Today!",
    address: "2-3 Bunyodkor Avenue, CIU Building, Tashkent, Uzbekistan 100043",
  },
  home: {
    header: {
      title: `Welcome to the International Olympiad of MU champs Centre!`,
      miniTitle:
        "Welcome to the international Olympiad of the MU champs center!",
      subtitle:
        "This multi-stage Olympiad offers a unique opportunity to test your abilities, learn, and potentially win an unforgettable prizes and especially a special trip to the UK! Through a series of meticulously crafted stages, participants will have the opportunity to showcase their English language proficiency, engage in stimulating linguistic tasks, and compete for the honor of being among the top English language scholars in their respective grades. Join us in this exciting journey to elevate your English skills, challenge your limits, and emerge victorious!",
      studentImg: "Students",
      testImg: "Tests",
    },
    olimpiad: {
      title: "MU champs Olimpiad",
      subtitle:
        "Get a chance to participate in the international Olympiad with the MU champs Center.",
    },
    roadmap: {
      title: "The Roadmap",
      happyPartners: "Partners happy",
      stage0: {
        title: "Stage 0: Registration",
        subtitle1:
          "Teachers: Register your school and provide contact details.",
        subtitle2: "Students: Choose or enter your school to register",
      },
      stage1: {
        title: "Stage 1: Online Test",
        subtitle:
          "In this stage students will take an online Cambridge English Placement test and after an online testing, only the first 100 participants from each grade will move to the Stage 2.",
      },
      stage2: {
        title: "Stage 2: Linguaskill Listening And Reading",
        subtitle:
          "The selected group of participants will move on to demonstrate their listening and reading skills through the Cabridge Assessment English Linguaskill test. Participants will be divided into groups according to their grades (9-10-11), with their results calculated separately to ensure a fair approach.",
      },
      stage3: {
        title: "Stage 3: Linguaskill Writing And Speaking",
        subtitle:
          "In this stage, students compete in the Cambridge Assessment English Linguaskill writing and speaking tasks. Scores will be calculated according to grades (9-10-11), leading to the identification of 3 exceptional winners from each grade who have demonstrated outstanding abilities.",
      },
      finalWinners: {
        title: "Final Winners:",
        subtitle1:
          "The Elevate English Challenge will culminate in the celebration of 9 winners, distributed across various grade levels, each awarded for their excellence in the English proficiency:",
        subtitle2:
          "Join us in this unparalleled adventure and seize the opportunity to be recognized INTERNATIONALLY. Register now to become a global citizen!",
        // grades1and2: "• Grades 1-2: Top 3 Places",
        // grades3and4: "• Grades 3-4: Top 3 Places",
        // grades5: "• Grade 5: Top 3 Places",
        // grades6and7: "• Grades 6-7: Top 3 Places",
        // grades8: "• Grade 8: Top 3 Places",
        grades9: "• Grade 9: Top 3 Places",
        grades10: "• Grade 10: Top 3 Places",
        grades11: "• Grade 11: Top 3 Places",
      },
    },
    whyChooseEdu: {
      title: "Why Choose MU champs:",
      box1: {
        title: "Expertise",
        subtitle:
          "To become a global Citizen by getting internationally recognized certifications.",
      },
      box2: {
        title: "Quality",
        subtitle:
          "We adhere to the highest standards of quality and integrity in all aspects of our operations, ensuring that our clients receive fair and accurate assessments.",
      },
      box3: {
        title: "Accessibility",
        subtitle:
          "We strive to make our examination services accessible to all, with convenient locations, flexible scheduling options, and supportive customer service.",
      },
    },
    registration: {
      title: "Registration",
      subtitle: "Olympiad Made Easy",
      student: {
        title: "For Students",
        fullName: "Full name",
        fullNameError: "Full name is required",
        date: "Date of birth",
        dateError: "Date of birth is required",
        email: "Email address",
        emailError: "Email address is required",
        phone: "Phone number",
        phoneError: "Phone number is required",
        phoneMessageError: "Enter the correct phone number",
        schoolNumber: "School number",
        schoolNumberError: "School number is required",
        selectError: "It is mandatory to choose one of the following",
        select: {
          grades1and2: "Grades 1-2",
          grades3and4: "Grades 3-4",
          grades5: "Grade 5",
          grades6and7: "Grades 6-7",
          grades8: "Grade 8",
          grades9: "Grade 9",
          grades10: "Grade 10",
          grades11: "Grade 11",
        },
      },
      school: {
        title: "For Schools",
        schoolNumber: "School number",
        schoolNumberError: "School number is required",
        constactPerson: "Contact person’s full name",
        constactPersonError: "Contact person’s full name is required",
        address: "Address",
        addressError: "Address is required",
        email: "Email",
        emailError: "Email is required",
        phone: "Phone number",
        phoneError: "Phone number is required",
        phoneMessageError: "Enter the correct phone number",
      },
      button: "Submit",
    },
  },
  about: {
    header: {
      titleMain: "About Us",
      subtitleMain:
        "Welcome to MU champs Exams Centre, your premier destination for internationally recognized examination services in Uzbekistan. At MU champs, we are committed to providing high-quality examination solutions that empower individuals to achieve their academic and professional goals.",
      title: "Our Mission",
      subtitle:
        "Our mission at MU champs Exams Centre is to empower individuals and institutions through access to world-class examination services. We believe that education is the key to unlocking opportunities and realizing potential, and we are committed to supporting our clients on their educational journeys.",
      students: "Students",
      partners: "Partners",
    },
    aboutUs: {
      title: "About Us",
      box1: {
        title: "About MU champs Exams Centre",
        subtitle:
          "Welcome to MU champs Exams Centre, your premier destination for internationally recognized examination services in Uzbekistan. At MU champs, we are committed to providing high-quality examination solutions that empower individuals to achieve their academic and professional goals.",
      },
      box2: {
        title: "Who We Are:",
        subtitle:
          "MU champs Exams Centre is a trusted leader in the field of examination and course services, serving students, professionals, teachers and institutions across Uzbekistan. With a team of dedicated professionals and a focus on excellence, we strive to deliver exceptional service and support to every client",
      },
      box3: {
        title: "What We Offer",
        subtitle:
          "MU champs offers a wide range of internationally recognized exams and qualifications to meet the diverse needs of our clients. Whether you are seeking to demonstrate English language proficiency, pursue academic opportunities abroad, or obtain professional certifications, we have exams such as IELTS, LINGUASKILL, TKT, DELTA and CELTA courses. And resources to help you succeed.",
      },
    },
    category: {
      IELTS: {
        title: "IELTS",
        subtitle:
          "We are the first IELTS Test Centre in Uzbekistan accredited by IDP Australia. The convenient location of MU champs at the Centre of the Tashkent near the Metro Novza allow us to provide comfortable conditions for taking the exam. The main principle of our centre is a client-oriented approach. Candidates can register online and as well as in our office, where our friendly members of staff are always ready to give you full support. The International English Language Testing System (IELTS) is one of the most widely recognized English language proficiency tests in the world. It is designed to assess the language ability of candidates who need to study or work where English is the language of communication. IELTS is accepted by over 10,000 organizations worldwide, including universities, employers, immigration authorities, and professional bodies.",
      },
      LINGUASKILL: {
        title: "LINGUASKILL",
        subtitle:
          "Linguaskill is a type of English language proficiency test developed by Cambridge Assessment English, part of the University of Cambridge. It is designed to assess the English language skills of individuals for academic, professional, or personal purposes. The test evaluates all four language skills: speaking, writing, reading, and listening. Linguaskill is computer-based and adaptive, meaning the difficulty level of the questions adjusts based on the test-taker's responses. This adaptive nature helps ensure that the test accurately measures the test-taker's language proficiency level.",
      },
      TKT: {
        title: "TKT",
        subtitle:
          "The Teaching Knowledge Test (TKT) is another examination offered by Cambridge Assessment English, specifically designed for teachers of English as a foreign language. It aims to assess the core knowledge and skills necessary for effective English language teaching. TKT does not assess the ability to teach, but rather focuses on understanding language concepts, teaching methodologies, and classroom management techniques. TKT is available globally and can be taken by both experienced and novice English language teachers. It serves as a valuable professional development tool, allowing teachers to demonstrate their knowledge and understanding of English language teaching principles and practices. Passing the TKT can enhance a teacher's credentials and open up opportunities for career advancement in the field of English language teaching.",
      },
      DELTA: {
        title: "DELTA",
        subtitle:
          "The Delta (Diploma in Teaching English to Speakers of Other Languages) is an advanced qualification for experienced English language teachers, offered by Cambridge Assessment English. It is designed for teachers who already have some experience in teaching English as a foreign or second language and wish to further develop their knowledge and skills.",
      },
      CELTA: {
        title: "CELTA",
        subtitle:
          "CELTA stands for Certificate in Teaching English to Speakers of Other Languages. It is a highly regarded initial qualification for those who wish to start a career in teaching English as a foreign language (TEFL). The CELTA course is designed and administered by Cambridge Assessment English, part of the University of Cambridge. CELTA courses are intensive and typically last four weeks full-time or part-time options are also available at our centre including online and face-to-face. During the course, trainees learn about various aspects of teaching English, including lesson planning, classroom management, language analysis, and teaching practice.",
      },
    },
    statisticas: {
      title: "The numbers don't lie",
      subtitle: "About 100+ new applicants every week",
      studentReg: "Students Registered",
      applicants: "New Applicants",
      tests: "Tests",
      awards: "Awards",
    },
    inTouch: {
      title: "Get In Touch",
      subtitle:
        "Ready to take the next step on your educational journey? Contact MU champs Exams Centre today to learn more about our services and schedule your exam. We look forward to helping you achieve your goals!.",
      button: "Apply",
    },
  },
  faq: {
    title: "Frequently Asked Questions",
    question1: {
      question:
        "What is the International English Language Olympiad for school students?",
      answer:
        "The International English Language Olympiad, run by the MU champs Exams Centre, is a competition that helps students improve and show off their English skills. It rewards those who are really good at English.",
    },
    question2: {
      question:
        "What opportunities does participating in the International English Language Olympiad offer to students?",
      answer:
        "Participating in the International English Language Olympiad gives students a chance to test their English skills, learn more, and possibly win amazing prizes, including a special trip to the UK!",
    },
    question3: {
      question:
        "What levels of difficulty are represented in the International English Language Olympiad?",
      answer:
        "In the International English Language Olympiad, all participants first take the official Cambridge English Assessment test. Then, a certain number of them move to the next round and compete using the Linguaskill test, which is also from Cambridge Assessment English.",
    },
    question4: {
      question:
        "How does participation in the International English Language Olympiad work?",
      answer: "📌 Registration process ni yozib chiqasiz. Proggress....",
    },
    question5: {
      question:
        "What are the requirements for participants in the International English Language Olympiad?",
      answer:
        "Participants in the International English Language Olympiad need to be school students in the 9th, 10th, or 11th grade. That's the main requirement for those looking to register and take part in the competition.",
    },
    question6: {
      question:
        "What preparatory resources are available for participants of the International English Language Olympiad?",
      answer:
        "Participants of the International English Language Olympiad can prepare for the 1st round using the Cambridge English Placement (https://www.cambridgeenglish.org/test-your-english/). For the subsequent rounds, they can use Linguaskill practice materials (https://www.cambridgeenglish.org/exams-and-tests/linguaskill/information-about-the-test/practice-materials/).",
    },
  },
  contactUs: {
    infos: {
      call: "Call Us",
      email: "Email",
      address: "Address",
      location:
        "2-3 Bunyodkor Avenue, CIU Building, Tashkent, Uzbekistan 100043",
    },
    inputs: {
      name: "Your name",
      email: "Your email",
      subject: "Subject",
      question: "Your questions...",
    },
    button: "Send Message",
  },
  signIn: {
    tabSchool: "for school",
    tabStudent: "for student",
    title: "Log In",
    userNameTitle: "Email address",
    userNameTitleError: "Email is required",
    userName: "Email address",
    password: "Password",
    passwordError: "Password is required",
    fogotPassword: "Fogot password?",
    button: "Login",
    signUpTitle: "Not registered yet?",
    signUp: "Sign Up",
  },
  signUp: {
    title: "Create Account",
    student: {
      title: "Student",
      userNameTitle: "Username or email address",
      userName: "User name",
      password: "Password",
      confirmPassword: "Confirm Password",
    },
    school: {
      title: "School",
      userNameTitle: "Username or email address",
      userName: "User name",
      password: "Password",
      confirmPassword: "Confirm Password",
    },
    agree: {
      title: "I agree to the",
      link: "Terms of User",
    },
    button: "Register",
    signInTitle: "Already have an account?",
    signIn: "Login Here",
  },
  changePassword: {
    tabSchool: "School",
    tabStudent: "Student",
    title: "Change Password",
    userNameTitle: "Email address",
    userNameTitleError: "Email is required",
    userName: "Email address",
    currentPassword: "Current password",
    newPassword: "New password",
    passwordError: "Password is required",
    button: "Change Password",
  },
};

export default en;
