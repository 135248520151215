import React from "react";
import Header2 from "../components/header/Header2";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import { useState } from "react";
import UserInfo from "../components/userInfo";

const Profil = () => {
  const [isShowMobile, setShowMobile] = useState(false);

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };
  //   const [toggle, setToggle] = useState({
  //     key: "",
  //     status: false,
  //   });
  //   const handleToggle = (key) => {
  //     if (toggle.key === key) {
  //       setToggle({
  //         status: false,
  //       });
  //     } else {
  //       setToggle({
  //         status: true,
  //         key,
  //       });
  //     }
  //   };
  return (
    <>
      <Header2 clname="actPage6" handleMobile={handleMobile} />
      <UserInfo />
      <Footer />
      <Gotop />
    </>
  );
};

export default Profil;
