import React from "react";
import { Link } from "react-router-dom";

import img from "../assets/images/not-found.jpg";

const NotFound = () => {
  return (
    <div
      style={{
        height: `${window.innerHeight}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        style={{ height: `${window.innerHeight - 80}px` }}
        src={img}
        alt="not-found images"
      />
      <Link
        style={{
          fontSize: "16px",
          fontWeight: 600,
          backgroundColor: "#14A077",
          padding: "8px 24px",
          borderRadius: "7px",
          color: "#ffffff",
        }}
        to="/"
      >
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
