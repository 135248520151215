import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./style.css";

const SchoolForm = (prop) => {
  let { submit, setSchoolValues, schoolValues } = prop.data;

  const { t } = useTranslation();

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    // reset,
  } = useForm({
    mode: "onBlur",
    // defaultValues: { schoolPhone: "+998 XX XXX XX XX" },
  });
  return (
    <>
      <div className="form-candidate">
        <form onSubmit={handleSubmit(submit)}>
          <div className="ip out s1">
            <label htmlFor="school-number">
              {t("home.registration.school.schoolNumber")}
            </label>
            <input
              {...register("schoolNumber", {
                required: t("home.registration.school.schoolNumberError"),
              })}
              name="schoolNumber"
              id="school-number"
              type="number"
              placeholder={t("home.registration.school.schoolNumber")}
              value={schoolValues.schoolNo || ""}
              onChange={(e) =>
                setSchoolValues({
                  ...schoolValues,
                  schoolNo: e.target.value,
                })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.schoolNumber && (
                <span>{errors?.schoolNumber.message}</span>
              )}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="person-full_name">
              {" "}
              {t("home.registration.school.constactPerson")}
            </label>
            <input
              {...register("personFullName", {
                required: t("home.registration.school.constactPersonError"),
              })}
              name="personFullName"
              id="person-full_name"
              type="text"
              placeholder={t("home.registration.school.constactPerson")}
              value={schoolValues.contactPerson || ""}
              onChange={(e) =>
                setSchoolValues({
                  ...schoolValues,
                  contactPerson: e.target.value,
                })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.personFullName && (
                <span>{errors?.personFullName.message}</span>
              )}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="address">
              {" "}
              {t("home.registration.school.address")}
            </label>
            <input
              {...register("address", {
                required: t("home.registration.school.addressError"),
              })}
              name="address"
              id="address"
              type="text"
              placeholder={t("home.registration.school.address")}
              value={schoolValues.address || ""}
              onChange={(e) =>
                setSchoolValues({
                  ...schoolValues,
                  address: e.target.value,
                })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.address && <span>{errors?.address.message}</span>}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="email">{t("home.registration.school.email")}</label>
            <input
              {...register("schoolEmail", {
                required: t("home.registration.school.emailError"),
              })}
              name="schoolEmail"
              id="email"
              type="email"
              placeholder={t("home.registration.school.email")}
              value={schoolValues.email || ""}
              onChange={(e) =>
                setSchoolValues({
                  ...schoolValues,
                  email: e.target.value,
                })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.schoolEmail && (
                <span>{errors?.schoolEmail.message}</span>
              )}
            </div>
          </div>
          <div className="ip out s1">
            <label htmlFor="school-phone">
              {t("home.registration.school.phone")}
            </label>
            <input
              {...register("schoolPhone", {
                required: t("home.registration.school.phoneError"),
                pattern: {
                  value:
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\. ]?[0-9]{2}[-\s\. ]?[0-9]{3}[-\s\. ]?[0-9]{2}[-\s\. ]?[0-9]{2}$/,
                  message: t("home.registration.school.phoneMessageError"),
                },
              })}
              name="schoolPhone"
              id="school-phone"
              type="tel"
              maxLength={17}
              placeholder="+998 12 345 67 89"
              value={schoolValues.phone || ""}
              onChange={(e) =>
                setSchoolValues({
                  ...schoolValues,
                  phone: e.target.value,
                })
              }
            />
            <div style={{ color: "red" }}>
              {errors?.schoolPhone && (
                <span>{errors?.schoolPhone.message}</span>
              )}
            </div>
          </div>
          <button
            className={`${isSubmitting && "school-form_disabled"}`}
            disabled={isSubmitting}
          >
            {t("home.registration.button")}
          </button>
        </form>
      </div>
    </>
  );
};

export default SchoolForm;
