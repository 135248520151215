import React, { useState } from "react";
import Header2 from "../components/header/Header2";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
// import SignUp from "../components/signUp";
// import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import { Collapse } from "react-collapse";
// import logo from "../assets/images/logo.png";
// import { Link } from "react-router-dom";
import Category from "../components/category";

function CreateAccount(props) {
  const { t } = useTranslation();
  // const [toggle, setToggle] = useState({
  //   key: "",
  //   status: false,
  // });
  const [isShowMobile, setShowMobile] = useState(false);

  // const handleToggle = (key) => {
  //   if (toggle.key === key) {
  //     setToggle({
  //       status: false,
  //     });
  //   } else {
  //     setToggle({
  //       status: true,
  //       key,
  //     });
  //   }
  // };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>
      <Header2 clname="actPage10" handleMobile={handleMobile} />
      <Breadcrumb title="register" />
      <br />
      <Category />
      <div
        className="sign-up"
        style={{ textAlign: "center", margin: "20px auto 40px auto" }}
      >
        {t("signUp.signInTitle")}
        <Link to="/login">
          <b> {t("signUp.signIn")}</b>
        </Link>
      </div>
      <br />
      <Footer />
      <Gotop />
    </>
  );
}

export default CreateAccount;
