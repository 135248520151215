/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import logo from "../../assets/images/logo-vertical.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { contacts, menuItems } from "../../utils/constants";
import { getCookies } from "../../hooks/cookie";

MobileMenu.propTypes = {};

function MobileMenu({ handleMobile }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <div className="content-tab">
            <nav className="main-nav mobile">
              <ul id="menu-primary-menu" className="menu">
                {menuItems.map(({ id, name, path, hidden }) =>
                  !hidden || getCookies("token") ? (
                    <li key={id} className="menu-item">
                      <Link to={path} className="iteam-menu">
                        {t(`navbar.${name}`)}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )
                )}
              </ul>
            </nav>
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help?</p>
                <h6>
                  <Link to={`tel:+${contacts.phone.replace(/\D/g, "")}`}>
                    {contacts.phone}
                  </Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to={contacts.telegram}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="15.5"
                      viewBox="0 0 496 512"
                    >
                      <path d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z" />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to={contacts.instagram}>
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                {/* <li>
                  <Link to={contacts.facebook}>
                    <i className="icon-facebook"></i>
                  </Link>
                </li> */}
                <li>
                  <Link to={contacts.youtube}>
                    <i className="icon-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;
