import React, { useState } from "react";
import "react-dropdown/style.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { register, registerSchool } from "../../api/client";
import useApi from "../../hooks/use-api";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import StudentForm from "./StudentForm";
import SchoolForm from "./SchoolForm";
import { useNavigate } from "react-router-dom";

function Category(props) {
  const { className } = props;
  const { t } = useTranslation();

  const [values, setValues] = useState({});
  const [schoolValues, setSchoolValues] = useState({});
  const navigate = useNavigate();

  const registerApi = useApi(register, "data");
  const registerSchoolApi = useApi(registerSchool, "data");

  const handleSubmit = async (data) => {
    // console.log(data);
    let finalData = {
      ...values,
      dateOfBirth: moment(values.dateOfBirth),
      dob: moment(values.dateOfBirth).format("DD/MM/YYYY"),
    };

    let result = await registerApi.request(finalData);

    if (result.status === 200) {
      toast.success(`Logged in successfully.`);
      navigate("/login");
      setValues({});
    } else {
      console.log(result);
      toast.error(`Error occured: ${result.data.message}`);
    }
  };

  const handleSchoolSubmit = async (data) => {
    // console.log(data);
    let finalData = { ...schoolValues };

    let result = await registerSchoolApi.request(finalData);

    if (result.status === 200) {
      toast.success(`Logged in successfully.`);
      setSchoolValues({});
    } else {
      console.log(result);
      toast.error(`Error occured: ${result.data.message}`);
    }
  };

  return (
    <section className={className}>
      <div className="tf-container" id="register">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="tf-title">
              <div className="group-title">
                <h1>{t("home.registration.title")}</h1>
                <p>{t("home.registration.subtitle")}</p>
              </div>
              {/* <Button title="All Categories" link="/joblist_v1" /> */}
            </div>
          </div>
          <div className="row tf-container">
            <Tabs className="wd-form-login tf-tab">
              <TabList className="menu-tab">
                <Tab className="ct-tab">
                  {t("home.registration.student.title")}
                </Tab>
                <Tab className="ct-tab">
                  {t("home.registration.school.title")}
                </Tab>
              </TabList>
              <div className="content-tab">
                <TabPanel className="inner animation-tab">
                  <StudentForm
                    data={{ submit: handleSubmit, setValues, values }}
                  />
                </TabPanel>
                <TabPanel className="inner animation-tab">
                  <SchoolForm
                    data={{
                      submit: handleSchoolSubmit,
                      setSchoolValues,
                      schoolValues,
                    }}
                  />
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category;
