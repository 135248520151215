const uz = {
  navbar: {
    home: "Bosh sahifa",
    aboutUs: "Biz haqimizda",
    faqs: "Savollar",
    services: "Xizmatlar",
    contactUs: "Aloqa",
    profil: "Profil",
    login: "Kirish",
    register: "Ro'yhatdan o'tish",
    logout: "Chiqish",
    profile: "Profil",
    language: "Til",
    olimpiad: "Olimpiada",
  },
  footer: {
    followUs: "Bizni Kuzatib Boring:",
    linksTitle: "Tez Havola",
    aboutUs: "Biz haqimizda",
    faqs: "Savollar",
    contactUs: "Aloqa",
    phoneInfo: "Yordam kerakmi?",
    addOlimpiad: "Bugun ingliz Olimpiadasiga qo'shiling!",
    address: "Bunyodkor ko'chasi, 2-3 uy, Toshkent, O'zbekiston 100043",
  },
  home: {
    header: {
      title: `MU champs markazi bilan xalqaro olimpiadada qatnashish imkoniyatiga ega bo'ling.`,
      miniTitle: "MU champs markazining xalqaro olimpiadasiga xush kelibsiz!",
      subtitle:
        "Ushbu ko'p bosqichli Olimpiada sizning qobiliyatingizni sinab ko'rish, o'rganish va potentsial ravishda unutilmas sovg'alar va ayniqsa Buyuk Britaniyaga maxsus sayohat qilish uchun noyob imkoniyatni taqdim etadi! Bir qator puxta ishlab chiqilgan bosqichlar orqali ishtirokchilar ingliz tilini bilish qobiliyatini namoyish etish, lingvistik vazifalarni rag'batlantirish bilan shug'ullanish va o'z sinflarida eng yaxshi ingliz tili olimlari qatorida bo'lish sharafi uchun kurashish imkoniyatiga ega bo'ladilar. Sizning ingliz ko'nikmalarini yuksaltirishda bu hayajonli safarda bizga qo'shiling, sizning chegaralarini qarshi, va g'olib paydo!",
      studentImg: "Talabalar",
      testImg: "Testlar",
    },
    olimpiad: {
      title: "MU champs olimpiadasi",
      subtitle:
        "MU champs markazi bilan xalqaro darajadagi olimpiadada ishtirok etish imkoniyatini qo‘lga kiriting.",
    },
    roadmap: {
      title: "Olimpiada xaritasi",
      happyPartners: "Baxtli hamkorlar",
      stage0: {
        title: "0-bosqich: Ro'yxatdan o'tish",
        subtitle1:
          "O'qituvchilar uchun: Maktabingizni kiritish orqali ro'yxatdan o'ting va aloqa ma'lumotlaringizni taqdim eting.",
        subtitle2:
          "Talabalar: Roʻyxatdan oʻtish uchun maktabingizni tanlang yoki kiriting.",
      },
      stage1: {
        title: "1-bosqich: Online test",
        subtitle:
          "Ushbu bosqichda talabalar onlayn Kembrij ingliz tilidan joylashish testini topshiradilar va onlayn testdan so'ng har bir sinfdan faqat birinchi 100 nafar ishtirokchi 2-bosqichga o'tadi.",
      },
      stage2: {
        title: "2-bosqich: Linguaskill Listening and Reading",
        subtitle:
          "Tanlangan ishtirokchilar guruhi Cambridge Assessment English Linguaskill testi orqali o‘zlarining tinglash va o‘qish qobiliyatlarini namoyish etishga o‘tadilar. Ishtirokchilar o‘z baholari (9-10-11) bo‘yicha guruhlarga bo‘linadi, ularning natijalari adolatli yondashuvni ta’minlash uchun alohida hisoblab chiqiladi.",
      },
      stage3: {
        title: "3-bosqich: Linguaksill Writing and Speaking",
        subtitle:
          "Ushbu bosqichda talabalar Cambridge Assessment English Linguaskill yozish va nutq vazifalarida raqobatlashadilar. Ballar (9-10-11) baholar bo'yicha hisoblab chiqiladi, bu har bir sinfdan ajoyib qobiliyatlarini namoyish etgan 3 ta istisno g'olibni aniqlashga olib keladi.",
      },
      finalWinners: {
        title: "Yakuniy g'oliblar:",
        subtitle1:
          "Elevate English Challenge 9 ta g‘olibni nishonlash bilan yakunlanadi, ular turli sinf darajalari bo‘yicha taqsimlanadi, ularning har biri ingliz tilini mukammal darajada bilishi uchun taqdirlanadi:",
        subtitle2:
          "Ushbu misli ko'rilmagan sarguzashtda bizga qo'shiling va XALQARO miqyosda tan olinish imkoniyatidan foydalaning. Jahon fuqarosi bo'lish uchun hozir ro'yxatdan o'ting!",
        grades9: "• 9-sinf: 3 nafar g’olib",
        grades10: "• 10-sinf: 3 nafar g’olib",
        grades11: "• 11-sinf: 3 nafar g’olib",
      },
    },
    whyChooseEdu: {
      title: "Nima uchun MU champs: ",
      box1: {
        title: "Tajriba",
        subtitle:
          "Xalqaro miqyosda tan olingan sertifikatlarni olish orqali global fuqaro bo'lish.",
      },
      box2: {
        title: "Sifat",
        subtitle:
          "Biz biznesimizning barcha qismlarida eng yuqori sifat va yaxlitlik standartlariga amal qilamiz, mijozlarimiz adolatli va aniq baholashni olishlarini ta'minlaymiz.",
      },
      box3: {
        title: "Foydalanish imkoniyati",
        subtitle:
          "Biz imtihon xizmatlarini qulay joylashuvlar, moslashuvchan rejalashtirish imkoniyatlari va mijozlarga samimiy xizmat ko'rsatish bilan hamma uchun ochiq bo'lishiga intilamiz.",
      },
    },
    registration: {
      title: "Ro'yxatdan o'tish",
      subtitle: "Olimpiada Osonlashdi",
      student: {
        title: "Talabalar Uchun",
        fullName: "To'liq ism",
        fullNameError: "To'liq ism talab qilinadi",
        date: "Tug'ilgan sana",
        dateError: "Tug'ilgan sana talab qilinadi",
        email: "Elektron pochta manzili",
        emailError: "Elektron pochta manzili talab qilinadi",
        phone: "Telefon raqami",
        phoneError: "Telefon raqami talab qilinadi",
        phoneMessageError: "To'g'ri telefon raqamini kiriting",
        schoolNumber: "Maktab raqami",
        schoolNumberError: "Maktab raqami talab qilinadi",
        selectError: "Quyidagilardan birini tanlash majburiydir",
        select: {
          grades1and2: "1-2 sinflar",
          grades3and4: "3-4 sinflar",
          grades5: "5 sinf",
          grades6and7: "6-7 sinflar",
          grades8: "8 sinf",
          grades9: "9 sinf",
          grades10: "10 sinf",
          grades11: "11 sinf",
        },
      },
      school: {
        title: "Maktablar Uchun",
        schoolNumber: "Maktab raqami",
        schoolNumberError: "Maktab raqami talab qilinadi",
        constactPerson: "Aloqa qiluvchi shaxsning to'liq ismi",
        constactPersonError:
          "Aloqa qiluvchi shaxsning toʻliq ismi koʻrsatilishi shart",
        address: "Manzil",
        addressError: "Manzil talab qilinadi",
        email: "Elektron pochta manzili",
        emailError: "Elektron pochta manzili talab qilinadi",
        phone: "Telefon raqami",
        phoneError: "Telefon raqami talab qilinadi",
        phoneMessageError: "To'g'ri telefon raqamini kiriting",
      },
      button: "Yuborish",
    },
  },
  about: {
    header: {
      titleMain: "Biz haqimizda",
      subtitleMain:
        "O'zbekistonda xalqaro miqyosda tan olingan imtihon xizmatlari uchun eng yaxshi manzil bo'lgan Edu-Action imtihonlari markaziga xush kelibsiz. Edu-Action - da biz shaxslarga ilmiy va kasbiy maqsadlariga erishish imkoniyatini beradigan yuqori sifatli imtihon echimlarini taqdim etishga sodiqmiz.",
      title: "Bizning vazifamiz",
      subtitle:
        "Edu-Action Exams Centre ning vazifasi jismoniy shaxslarga va turli muassasalarga jahon darajasidagi imtihon xizmatlaridan foydalanish imkoniyatini berishdir. Biz ta'lim imkoniyatlarni ingliz tili orqali ochish va potentsialni ro'yobga chiqarishning kalitlaridan biri ekanligiga ishonamiz va biz mijozlarimizni ta'lim yo'lida qo'llab-quvvatlashga intilamiz.",
      students: "Talabalar",
      partners: "Hamkorlar",
    },
    aboutUs: {
      title: "Biz Haqimizda",
      box1: {
        title: "Edu-Action Exams Centre haqida",
        subtitle:
          "O‘zbekistonda xalqaro tan olingan imtihon xizmatlarini olishingiz mumkin bo‘lgan Edu-Action markaziga xush kelibsiz. Edu-Action da biz odamlarga akademik va kasbiy maqsadlariga erishish imkonini beruvchi yuqori sifatdagi imtihinlarni tashkil etishga intilamiz.",
      },
      box2: {
        title: "Biz kimmiz:",
        subtitle:
          "Edu-Action Exams Centre Oʻzbekiston boʻylab talabalar, mutaxassislar, oʻqituvchilar va taʼlim muassasalariga xizmat koʻrsatuchi imtihon va kurs xizmatlari boʻyicha ishonchli va yetakchi markazlardan biri hisoblanadi.",
      },
      box3: {
        title: "Biz nima taklif qilamiz:",
        subtitle:
          "Edu-Action mijozlarimizning turli ehtiyojlarini qondirish uchun xalqaro miqyosda tan olingan imtihonlar va sertifikatlarning keng doirasini taklif etadi. Ingliz tilini bilish darajasini ko'rsatishni, xorijda o'qishni davom ettirishni yoki professional sertifikat olishni xohlaysizmi? Biz IELTS, Cambridge Exams, LINGUASKILL, TKT, DELTA imtihinlari va CELTA kurslarini va muvaffaqiyatga erishishga yordam beradigan manbalarni taklif etamiz.",
      },
    },
    category: {
      IELTS: {
        title: "IELTS",
        subtitle:
          "Biz IDP Australia tomonidan akkreditatsiya qilingan O‘zbekistondagi birinchi va yagona IELTS test markazimiz. “MU champs”ning Toshkent shahri markazida, “Novza” metro bekati yaqinida qulay joylashuvi bizga imtihon topshirish uchun qulay sharoit yaratish imkonini beradi. Markazimizning asosiy tamoyili - mijozga yo'naltirilgan yondashuv. Nomzodlar onlayn yoki bizning ofisimizda ro'yxatdan o'tishlari mumkin, bu yerda bizning samimiy xodimlarimiz sizga har tomonlama yordam berishga doimo tayyor. Xalqaro ingliz tili test tizimi (IELTS) dunyodagi eng keng tarqalgan ingliz tili testlaridan biridir. U ingliz tili muloqot tili bo'lgan joyda o'qishi yoki ishlashi kerak bo'lgan nomzodlarning til qobiliyatlarini baholash uchun mo'ljallangan. IELTS imtihonini butun dunyo boʻylab 10 000 dan ortiq tashkilotlar, jumladan universitetlar, ish beruvchilar, immigratsiya organlari va professional tashkilotlar qabul qiladi.",
      },
      LINGUASKILL: {
        title: "LINGUASKILL",
        subtitle:
          "Linguaskill - bu Kembrij universiteti tarkibiga kiruvchi Cambridge Assessment English tomonidan ishlab chiqilgan ingliz tili imtihonining bir turi. U akademik, professional yoki shaxsiy maqsadlarda ingliz tilini bilish darajasini baholash uchun mo'ljallangan. Sinov barcha to'rtta til ko'nikmalarini baholaydi: gapirish, yozish, o'qish va tinglash. Linguaskill kompyuter texnologiyasiga asoslangan va moslashuvchan, ya'ni savollarning qiyinlik darajasi test topshiruvchining javoblariga qarab sun’iy intellect yordamida o'zgaradi. Testning moslashuv xususiyati va baholash jarayonida sun’iy intellektning borligi tilni bilish darajasini aniq aniqlash imkonini beradi.",
      },
      TKT: {
        title: "TKT",
        subtitle:
          "Teaching Knowledge Test (TKT) Cambridge Assessment English tomonidan taklif qilinadigan yana bir imtihon bo'lib, ingliz tilini chet tili sifatida dars o’tadigan o'qituvchilar uchun maxsus mo'ljallangan. Uning maqsadi ingliz tilini o'rgatish mahoratidagi asosiy bilim va ko'nikmalarni baholashdan iborat. TKT o'qitish qobiliyatini baholamaydi, balki til tushunchalari, o'qitish texnikasi va sinfni boshqarish usullarini tushunishga qaratilgan. TKT butun dunyo bo'ylab mavjud va uni ham tajribali, ham yangi ingliz tili o'qituvchilari olishlari mumkin. U oʻqituvchilarga ingliz tilini oʻqitish tamoyillari va amaliyotlari boʻyicha oʻz bilim va tushunchalarini namoyish etish imkonini beruvchi qimmatli kasbiy rivojlanish vositasi boʻlib xizmat qiladi. TKTdan o'tish o'qituvchining malakasini oshirishi va ingliz tilini o'qitishda martaba imkoniyatlarini ochishi mumkin.",
      },
      DELTA: {
        title: "DELTA",
        subtitle:
          "DELTA (Diploma in Teaching English to Speaking to English) Cambridge Assessment English tomonidan taqdim etiladigan tajribali ingliz oʻqituvchilari uchun ilgʻor malaka hisoblanadi. U ingliz tilini xorijiy yoki ikkinchi til sifatida o‘qitishda ma’lum tajribaga ega bo‘lgan va o‘z bilim va ko‘nikmalarini rivojlantirishni xohlovchi o‘qituvchilar uchun mo‘ljallangan.",
      },
      CELTA: {
        title: "CELTA",
        subtitle:
          "CELTA boshqa tillarda so'zlashuvchilarga ingliz tilini o'rgatish sertifikati degan ma'noni anglatadi. Bu ingliz tilini chet tili o'qituvchisi sifatida boshlash istagida bo'lganlar uchun juda boshlang’ich sertifikatdir. CELTA kursi Kembrij universiteti tarkibiga kiruvchi Cambridge Assessment English tomonidan ishlab chiqilgan va doimiy ravishda tekshirib boriladi. CELTA kurslari odatda intensiv ravishda to'rt hafta davom etadi. Shuningdek, bizning markazimiz part-time oflayn va onlayn rejimdagi kurslarni ham taklif etadi. Kurs davomida o’qituvchilar ingliz tilini o‘qitishning turli jihatlarini, jumladan, darsni rejalashtirish, sinfni boshqarish, til tahlili va o‘qitish amaliyotini professional trenerlardan o‘rganadilar.",
      },
    },
    statisticas: {
      title: "Raqamlar yolg'on gapirmaydi",
      subtitle: "Har hafta 100 + yangi abituriyentlar haqida",
      studentReg: "Roʻyxatdan oʻtgan talabalar",
      applicants: "Yangi abituriyentlar",
      tests: "Testlar",
      awards: "Mukofotlar",
    },
    inTouch: {
      title: "Murojat qiling",
      subtitle:
        "Ta'lim safaringizda keyingi qadamni qo'yishga tayyormisiz? Xizmatlarimiz haqida ko'proq bilish va imtihoningizni rejalashtirish uchun bugun MU champs imtihonlari markaziga murojaat qiling. Maqsadlaringizga erishishda sizga yordam berishni orziqib kutamiz!.",
      button: "Murojaat qiling",
    },
  },
  faq: {
    title: "Tez-tez so'raladigan savollar",
    question1: {
      question:
        "Maktab o‘quvchilari uchun ingliz tili bo‘yicha xalqaro olimpiada nima?",
      answer:
        "MU champs test markazi tomonidan o‘tkaziladigan Xalqaro ingliz tili olimpiadasi maktab o‘quvchilariga ingliz tilini o‘rganish va ko‘nikmalarini oshirishga yordam beradigan musobaqadir. Ingliz tilini chindan ham yaxshi biladiganlarni mukofotlaydi.",
    },
    question2: {
      question:
        "Xalqaro ingliz tili olimpiadasida qatnashish talabalar uchun qanday imkoniyatlarni ochib beradi?",
      answer:
        "Xalqaro ingliz tili olimpiadasida ishtirok etish o‘quvchilarga ingliz tilidagi ko‘nikmalarini sinab ko‘rish, ko‘proq o‘rganish va ajoyib sovrinlarni qo‘lga kiritish imkoniyatini beradi, jumladan, Buyuk Britaniyaga maxsus sayohat!.",
    },
    question3: {
      question:
        "Xalqaro ingliz tili olimpiadasida qanday qiyinchilikdagi darajalar berilgan?",
      answer:
        "Xalqaro ingliz tili olimpiadasida barcha ishtirokchilar birinchi navbatda rasmiy Cambridge English Placement testini topshirishadi. Tanlangan ishtirokchilar keyingi bosqichga o‘tadilar va Cambridge Assessment English tomonidan ishlab chiqilgan Linguaskill testi yordamida raqobatlashadilar.",
    },
    question4: {
      question: "Xalqaro ingliz Olimpiadasida ishtirok etish qanday ishlaydi?",
      answer: "📌 Registration process ni yozib chiqasiz. Proggress....",
    },
    question5: {
      question:
        "Xalqaro ingliz tili olimpiadasi ishtirokchilariga qanday talablar qo‘yiladi?",
      answer:
        "Xalqaro ingliz tili olimpiadasi ishtirokchilari 9, 10 yoki 11-sinf o‘quvchilari bo‘lishi kerak va ID karta yoki xorijga chiqish passporti bo’lishi kerak. Bu roʻyxatdan oʻtish va olimpiadada qatnashishni xohlovchilar uchun asosiy talabdir.",
    },
    question6: {
      question:
        "Xalqaro ingliz tili olimpiadasi ishtirokchilari uchun qanday tayyorgarlik resurslari mavjud?",
      answer: `Xalqaro ingliz tili olimpiadasi ishtirokchilari Cambridge English Placement (https://www.cambridgeenglish.org/test-your-english/) yordamida 1-bosqichga tayyorlanishlari mumkin. Keyingi bosqichlarda ular Linguaskill amaliyoti materiallaridan foydalanishlari mumkin (https://www.cambridgeenglish.org/exams-and-tests/linguaskill/information-about-the-test/practice-materials/.)`,
    },
  },
  contactUs: {
    infos: {
      call: "Bizga Qo'ng'iroq Qiling",
      email: "Elektron pochta",
      address: "Manzil",
      location: "Bunyodkor ko'chasi, 2-3 uy, Toshkent, O'zbekiston 100043",
    },
    inputs: {
      name: "Sizning ismingiz",
      email: "Sizning elektron pochtangiz",
      subject: "Mavzu",
      question: "Sizning savollaringiz...",
    },
    button: "Xabar Yuborish",
  },
  signIn: {
    tabSchool: "maktab uchun",
    tabStudent: "talabalar uchun",
    title: "Kirish",
    userNameTitle: "Elektron pochta manzili",
    userNameTitleError: "Elektron pochta manzili talab qilinadi",
    userName: "Elektron pochta manzili",
    password: "Parol",
    passwordError: "Parol talab qilinadi",
    fogotPassword: "Parolni unutdingizmi?",
    button: "Kirish",
    signUpTitle: "Hali ro'yxatdan o'tmaganmisiz?",
    signUp: "Ro'yxatdan O'tish",
  },
  signUp: {
    title: "Hisob Yaratish",
    student: {
      title: "Talaba",
      userNameTitle: "Foydalanuvchi nomi yoki elektron pochta manzili",
      userName: "Foydalanuvchi nomi",
      password: "Parol",
      confirmPassword: "Parolni Tasdiqlang",
    },
    school: {
      title: "Maktab",
      userNameTitle: "Foydalanuvchi nomi yoki elektron pochta manzili",
      userName: "Foydalanuvchi nomi",
      password: "parol",
      confirmPassword: "Parolni Tasdiqlang",
    },
    agree: {
      title: "Men roziman",
      link: "Foydalanish shartlari",
    },
    button: "Ro'yxatdan o'tish",
    signInTitle: "Allaqachon hisobingiz bormi?",
    signIn: "Kirish Bu Yerga",
  },
  changePassword: {
    tabSchool: "Maktab",
    tabStudent: "Talaba",
    title: "Parolni o'zgartirish",
    userNameTitle: "Elektron pochta manzili",
    userNameTitleError: "Elektron pochta manzili talab qilinadi",
    userName: "Elektron pochta manzili",
    currentPassword: "Joriy parol",
    newPassword: "Yangi parol",
    passwordError: "Parol talab qilinadi",
    button: "Parolni o'zgartirishx",
  },
};

export default uz;
