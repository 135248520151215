import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/use-api";
import { login, loginSchool } from "../api/client";
import { toast } from "react-toastify";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useTranslation } from "react-i18next";
import { setCookies } from "../hooks/cookie";
import Header2 from "../components/header/Header2";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import SignInSchool from "../components/signin/signInSchool";
import SignInStudent from "../components/signin/signInStudent";

// import { Collapse } from "react-collapse";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowMobile, setShowMobile] = useState(false);
  const [data, setData] = useState({});
  const [dataSchool, setDataSchool] = useState({});

  const loginApi = useApi(login, "data");
  const loginSchoolApi = useApi(loginSchool, "data");

  const handleSubmit = async (data) => {
    let result = await loginApi.request(data);

    if (result.status === 200) {
      toast.success(`Logged in successfully.`);
      setCookies("token", result?.data?.token);
      setData({});
      navigate("/profile");
    } else {
      console.log(result);
      toast.error(`Error occured: ${result.data.message}`);
    }
  };
  const handleSchoolSubmit = async (data) => {
    let result = await loginSchoolApi.request(data);
    console.log(result);

    if (result.status === 200) {
      toast.success(`Logged in successfully.`);
      setCookies("token", result?.data?.token);
      setData({});
      navigate("/profile");
    } else {
      console.log(result);
      toast.error(`Error occured: ${result.data.message}`);
    }
  };

  // const [toggle, setToggle] = useState({
  //   key: "",
  //   status: false,
  // });
  // const handleToggle = (key) => {
  //   if (toggle.key === key) {
  //     setToggle({
  //       status: false,
  //     });
  //   } else {
  //     setToggle({
  //       status: true,
  //       key,
  //     });
  //   }
  // };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>
      <Header2 clname="actPage9" handleMobile={handleMobile} />
      <Breadcrumb title="login" />
      <section className="account-section">
        <div className="wd-form-login">
          <h4>{t("signIn.title")}</h4>
          <div className="row tf-container">
            <Tabs className="wd-form-login tf-tab">
              <TabList className="menu-tab">
                <Tab className="ct-tab">{t("signIn.tabStudent")}</Tab>
                <Tab className="ct-tab">{t("signIn.tabSchool")}</Tab>
              </TabList>
              <div className="content-tab">
                <TabPanel className="inner animation-tab">
                  <SignInStudent
                    data={{
                      submit: handleSubmit,
                      setData,
                      data,
                    }}
                  />
                </TabPanel>
                <TabPanel className="inner animation-tab">
                  <SignInSchool
                    data={{
                      submit: handleSchoolSubmit,
                      setDataSchool,
                      dataSchool,
                    }}
                  />
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </section>
      <Footer />
      <Gotop />
    </>
  );
}

export default Login;
