import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header2 from "../components/header/Header2";
import Footer from "../components/footer";
import Review from "../components/aboutPage/Review";
import Gotop from "../components/gotop";
import BannerCounter from "../components/aboutPage/BannerCounter";
import Box09 from "../components/boxicon/Box09";
import Term from "../components/term";
// import PropTypes from "prop-types";
// import Breadcrumb from "../components/breadcrumb";
// import img1 from "../assets/images/job/work-icon-1.jpg";
// import img2 from "../assets/images/job/work-icon-2.jpg";
// import img3 from "../assets/images/job/work-icon-3.jpg";
// import Iconbox3 from "../components/aboutPage/Iconbox3";
// import Testimonial from "../components/aboutPage/Testimonial";
// import dataTestimonials from "../assets/fakeData/dataTestimonials";
// import Partner from "../components/aboutPage/Partner";
// import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import { Collapse } from "react-collapse";
// import logo from "../assets/images/logo.png";

AboutUs.propTypes = {};

function AboutUs() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isShowMobile, setShowMobile] = useState(false);

  // const [toggle, setToggle] = useState({
  //   key: "",
  //   status: false,
  // });
  // const handleToggle = (key) => {
  //   if (toggle.key === key) {
  //     setToggle({
  //       status: false,
  //     });
  //   } else {
  //     setToggle({
  //       status: true,
  //       key,
  //     });
  //   }
  // };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  // box09 information
  const box09Info = {
    boxTitle: t("about.aboutUs.title"),
    infoOne: {
      title: t("about.aboutUs.box1.title"),
      subtitle: t("about.aboutUs.box1.subtitle"),
    },
    infoTwo: {
      title: t("about.aboutUs.box2.title"),
      subtitle: t("about.aboutUs.box2.subtitle"),
    },
    infoThere: {
      title: t("about.aboutUs.box3.title"),
      subtitle: t("about.aboutUs.box3.subtitle"),
    },
  };
  const termInfo = [
    {
      id: 1,
      title: t("about.category.IELTS.title"),
      subtitle: t("about.category.IELTS.subtitle"),
    },
    {
      id: 2,
      title: t("about.category.LINGUASKILL.title"),
      subtitle: t("about.category.LINGUASKILL.subtitle"),
    },
    {
      id: 3,
      title: t("about.category.TKT.title"),
      subtitle: t("about.category.TKT.subtitle"),
    },
    {
      id: 4,
      title: t("about.category.DELTA.title"),
      subtitle: t("about.category.DELTA.subtitle"),
    },
    {
      id: 5,
      title: t("about.category.CELTA.title"),
      subtitle: t("about.category.CELTA.subtitle"),
    },
  ];
  return (
    <>
      <Header2 clname="actPage5" handleMobile={handleMobile} />
      {/* <Breadcrumb title="About Us" /> */}
      <Review />
      <Box09 data={box09Info} />
      <Term data={termInfo} />
      <BannerCounter />

      <section className="wd-banner-CTA">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12  wow fadeInUp">
              <div className="group-banner-CTA">
                <h3>{t("about.inTouch.title")}</h3>
                <p>{t("about.inTouch.subtitle")}</p>
                <div className="group-btn">
                  <button
                    onClick={() => navigate("/contactus")}
                    className="tf-btn"
                  >
                    {t("about.inTouch.button")}
                  </button>
                  {/* <button className="tf-btn">Find Candidates</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Gotop />
    </>
  );
}

export default AboutUs;
